import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import GooglePlay from "../../assets/image/googlePlay.svg";
import Appstore from "../../assets/image/appStore.svg";

import Facebook from "../../assets/image/facebook.svg";
import Instagram from "../../assets/image/instagram.svg";
import Twiter from "../../assets/image/twiter.svg";
import { Link } from "react-router-dom";



import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";


const IdentidadeMunicipal = () => {
   

    const carousel = useRef();
    const [width, setwidth] = useState(0);

    useEffect(() => {
        setwidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
    }, [])



    return (

        <section className="container">

            <section className="home_corpo2">
                <div className="pesquisa" >

                    <section className="home_topo_conteudo" href="/">
                        <img src={LogoColor} alt="logo" className="logoColor" href="/" />
                        <img src={LogoColor} alt="logo" className="logo" href="/" />
                    </section>

                    <div className="ID">Detalhes da empresa</div>
                    <div className="ID2">
                        Informações da empresa, a partir de 1 de Janeiro de 2023:

                        <p /><strong>Provedor do site e parte contratada para serviços de pagamento
                            <br />para usuários residentes ou estabelecidos no Brasil:</strong>

                        <p />Sllips Ltda.
                        <br />Capitólio - MG - Brasil
                        <br />CNPJ: 46.055.152/0001-92


                        <p /><strong>Fale conosco:</strong>
                        <p />atendimento@sllips.com
                        <p />https://www.sllips.com.br/InformaçõesDaEmpresa

                        <p /><strong>Seus dados pessoais</strong>

                        <p />Para que você possa exercer seus direitos em relação aos seus dados pessoais, acesse aqui

                        <p />Caso você tenha alguma dúvida sobre privacidade ou proteção de dados no Sllips, pode
                        <br />entrar em contato com nosso Encarregado pelo Tratamento de Dados (Data Protection Officer, DPO)
                        <br />através do email: atendimento@sllips.com                              
                        
                        <p /><strong>Reliase: v2.0.2</strong>
                        
                        </div>

                        

                    <div className="linha"></div>


                </div>

            </section>



            <section className="home_rodape">
                <div className="box1 flex">


                    <div className="bloco1">

                        <div className="text1">Visitante</div>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Cadastro Turístico Municipal</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Fazer Cadastro CTM</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Ajuda e suporte</div>

                        <Link className="box" to="/InformaçõesDaEmpresa">
                            <div className="text2">Central de ajuda</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Perguntas Frequentes</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Empresa</div>

                        <Link className="box" to="/NossaHistoria">
                            <div className="text2">Nossa história</div>
                        </Link>

                    </div>
                </div>

                <div className="box2 flex">
                    <div>
                        <div className="baixe">Baixe agora</div>
                        <img src={GooglePlay} alt="Google Play" />
                    </div>
                    <div>
                        <div className="disponivel">O app esta disponível <br />apenas para atividade <br />comerciais</div>
                        <img src={Appstore} alt="App Store" />
                    </div>
                </div>
            </section>
            <section className="home_rodape">

                <div className="text">© 2022 Sllips LTDA. All rights reserved</div>

                <Link className="a" to="/Privacidade">
                    <div className="text">.Política de Privacidade</div>
                </Link>

                <Link className="a" to="/InformaçõesDaEmpresa">
                    <div className="text">.Informações da empresa</div>
                </Link>

                <div className="social">

                    <Link className="a" to="https://www.instagram.com/sllips.oficial/">
                        <img src={Instagram} alt="Instagram" />
                    </Link>
                    <img src={Facebook} alt="facebook" />
                    <img src={Twiter} alt="Twiter" />

                </div>
            </section>
        </section>
    )
}

export default IdentidadeMunicipal;