

import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo_color.svg";
import Dropdown from "../../components/Dropdown";
import DropdownCity from "../../components/DropdownCity";
import DropdownUf from "../../components/DropdownUf";
import Rodape from "../../components/Rodape";
import { useState, useEffect, useRef } from "react";
import InputText from "../../components/InputText";
import IMask from "imask";
import { MuiTelInput } from 'mui-tel-input'

import LogoColor from "../../assets/image/logo_color.svg";

import axios from 'axios';


const CadastroEmpresa = () => {


    const navigation = useNavigate();

    const [cidade, setCidade] = useState("");
    const [estadouf, setEstadoUF] = useState('');
    const [pais, setPais] = useState('');

    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [contato, setContato] = useState("");
    const [parentesco, setParentesco] = useState("");
    const [emergenciaTel, setEmergenciaTel] = useState("");

    const [selectedUf, setSelectedUf] = useState("0");

    const [dataCidade, setDataCidade] = useState("");
    const [dataUf, setDataUf] = useState("");

    const [razaosocial, setRazaoSocial] = useState("");
    const [cnpj, setCnpj] = useState(null)


    const opcoes = [
        { key: 1, value: "Pai" },
        { key: 2, value: "Mãe" },
        { key: 3, value: "Irmão(a)" },
        { key: 4, value: "Filho(a)" },
        { key: 5, value: "Tio(a)" },
        { key: 6, value: "Avô(ó)" },
        { key: 7, value: "Esposa" },
        { key: 8, value: "Marido" }
    ];



    const handleAvanca = () => {
        localStorage.setItem("@agencia:Etapa1", JSON.stringify({
            cidade,
            email,
            telefone,
            nome: contato,
            parentesco,
            emergenciaTel,
            pais,
            estadouf,
            razaosocial,
            cnpj: cnpj,

        }));
        navigation("/Onibus")
    }



    useEffect(() => {


        const dados = JSON.parse(localStorage.getItem("@agencia:Etapa1"));

        if (dados != null) {

            setCidade(dados.cidade);
            setEmail(dados.email);
            setTelefone(dados.telefone);
            setContato(dados.nome);
            setParentesco(dados.parentesco);
            setEmergenciaTel(dados.emergenciaTel)

            setEstadoUF(dados.cidade);
            setPais(dados.pais);

            setRazaoSocial(dados.razaosocial);
            setCnpj(dados.cnpj);

        }
    }, []);

    const TelefoneMask = (value, info) => {

        if (value.length <= 17) {
            setTelefone(value);
        }
    }

    const TelefoneMaskEmergencial = (value, info) => {
        if (value.length <= 17) {
            setEmergenciaTel(value);
        }
    }



    const maskRef = useRef(null);

    const maskOptionsCnpj = {
        mask: "00.000.000/0000-00"
    }

    useEffect(() => {
        if (cnpj) {
            maskRef.current = IMask(document.getElementById("visitanteCNPJ"), maskOptionsCnpj);
        }

    }, [cnpj]);




    //////////////////////// Rotina buscar cidade e estado //////////////////////////

    useEffect(() => {
        if (selectedUf === "0") {
            return;
        }
        try {
            axios
                .get(
                    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`
                )
                .then((response) => {
                    setDataCidade(response.data);

                });

        }
        catch (err) {
            console.log("Erro ao buscar dados da api: ", err);
        }

    }, [selectedUf]);

    useEffect(() => {
        axios
            .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/")
            .then((response) => {
                setDataUf(response.data);
            });
    }, [pais]);

    function handleSelectUf(value) {
        setSelectedUf(value);
        setEstadoUF(value);
    }

    function handleSelectCity(value) {
        setCidade(value);
    }




    ////////////////////////////////////////////////////////////////////////////////////////////////

    // const maskOptions = {
    //   mask: " (000) 00000 0000"
    // }
    // const maskRef = useRef(null);

    // useEffect(() => {


    //   maskRef.current = IMask(document.getElementById("emergenciaTel"), maskOptions);
    //   maskRef.current = IMask(document.getElementById("telefone"), maskOptions);

    // }, [emergenciaTel, telefone]);

    return (
        <form className="container" onSubmit={handleAvanca}>

            <section className="home_topo_conteudo">
                <img src={LogoColor} alt="logo" className="logoColor" />
                <img src={LogoColor} alt="logo" className="logo" />
            </section>



            <div className="corpo flex">
                <div className="text1-black">Cadastre sua agência</div>
                <div className="text2">
                    Preencha os dados do perfil. Isso levará alguns segundos. <br />
                    <strong>
                        Depois ganhe tempo na visitação dos pontos turísticos!
                    </strong>
                </div>

                <InputText
                    title="Razão Social"
                    placeholder="Nome da empresa"
                    value={razaosocial}
                    onchange={(item) => setRazaoSocial(item.target.value)}
                    msnWarning="Informe o nome do contato."
                    required={true}
                />
                <InputText
                    title="CNPJ da empresa"
                    id="visitanteCNPJ"
                    // title="CPF"
                    placeholder="00.000.000/0000-00"
                    value={cnpj}
                    onchange={item => setCnpj(item.target.value)}
                    msnWarning="Campo precisa ser preenchido"
                    required={true}
                />



                {/* <div className="containerbloco" class="col-2 flex">

          <InputText
            title="Qual País você mora?"
            placeholder="Selecionar País"
            value={pais}
            onchange={(item) => setPais(item.target.value)}
            msnWarning="Informe o País que você mora."
            required={true}
          />

          <InputText
            title="Qual Estado você mora?"
            placeholder="Selecionar Estado"
            value={estadouf}
            onchange={(item) => setEstadoUF(item.target.value)}
            msnWarning="Informe o Estado que você mora."
            required={true}
          />

          <InputText
            title="Qual cidade você mora?"
            placeholder="Nome da cidade"
            value={cidade}
            onchange={(item) => setCidade(item.target.value)}
            msnWarning="Informe a cidade em você mora."
            required={true}
          />


       
        </div> */}

                <InputText
                    title="País"
                    placeholder="País"
                    value={pais}
                    onchange={(item) => setPais(item.target.value)}
                    msnWarning="Informe o País que você está localizada."
                    required={true}
                />

                <div className="textInput"> Estado </div>
                <DropdownUf
                    data={dataUf}
                    placeholder="Selecionar Estado"
                    onchange={(event) => handleSelectUf(event.target.value)}
                    value={estadouf}
                    msnWarning="Informe o Estado que você está localizada."
                    required={true}
                />

                {estadouf &&
                    <>
                        <div className="textInput"> Cidade </div>

                        <DropdownCity
                            data={dataCidade}
                            placeholder="Selecionar Cidade"
                            onchange={(event) => handleSelectCity(event.target.value)}
                            value={cidade}
                            msnWarning="Informe o Estado que você está localizada."
                            required={true}
                        />
                    </>
                }




                <div className="text1-black marginTop">Contato</div>
                <div className="text2">
                    É importante ter um número emergencial cadastrado.
                </div>

                {/* <InputText
          id="telefone"
          title="Telefone de contato"
          placeholder="+{00} (000) 00000 0000"
          value={telefone}
          onchange={(item) => setTelefone(item.target.value)}
          msnWarning="Informe seu telefone."
          required={true}
        /> */}


                <InputText
                    type="email"
                    title="Email"
                    placeholder="Digite seu Email"
                    value={email}
                    onchange={(item) => setEmail(item.target.value)}
                    msnWarning="Informe seu email."
                    required={true}
                />


                <div class="col-5 flex">
                    <div>

                        <div className="textInput"> Telefone de contato </div>

                        <MuiTelInput
                            id="telefone"
                            variant="outlined"
                            placeholder="(00) 00000 0000"
                            defaultCountry="BR"
                            value={telefone}
                            onChange={TelefoneMask}
                            flagSize="medium"
                            forceCallingCode


                            inputProps={
                                {
                                    style: { fontSize: 15, border: 10 }
                                }
                            }

                        />


                    </div>

                    <div>

                        <div className="textInput"> Telefone de emergência </div>

                        <MuiTelInput
                            id="emergenciaTel"
                            variant="outlined"
                            placeholder="(00) 00000 0000"
                            defaultCountry="BR"
                            value={emergenciaTel}
                            onChange={TelefoneMaskEmergencial}
                            flagSize="medium"
                            forceCallingCode

                            inputProps={
                                {
                                    style: { fontSize: 15, border: 10 }
                                }
                            }

                        />


                    </div>





                </div>



            </div>

            <Rodape
                valorBar={49.8}
                voltar={() => navigation(-1)}
                avancar={true}
            />

        </form>
    )
}

export default CadastroEmpresa;