import "../components/Dropdown.css";
import Warning from "../assets/image/warning.png";
const Dropdown = ({ data, placeholder, onchange, value, msnWarning, required}) => {
    return (
        <>
            <select required={required} className="dropdown" onChange={onchange} value={value}>
                <option value="" disabled selected>{placeholder}</option>

                {data.map((item) => {
                    return <option className="option" key={item.key} value={item.value}>{item.value}</option>
                })}

            </select>
            <div className="warning">
                <img src={Warning} alt="ícone de alerta" />
                {msnWarning}
            </div>
        </>
    )
}

export default Dropdown;
