import { useNavigate } from "react-router-dom";
// import Logo from "../../assets/image/logo_color.svg";
import { useState, useEffect, useRef } from "react";
import InputText from "../../components/InputText";
import Rodape from "../../components/Rodape";
import IMask from "imask";
import InputMask from 'react-input-mask';

import LogoColor from "../../assets/image/logo_color.svg";

//vc tem que importar o componente para poder utilizar ele na pagina, com isso vc pode usar o mesmo item em mais de um lugar  
import Dropdown from "../../components/Dropdown";

const CadastroAcompanhante = () => {

    const navigation = useNavigate();

    const [responsavel, setResponsavel] = useState(true);
    const [nome, setNome] = useState("");
    const [sobrenome, setSobrenome] = useState("");
    const [sexo, setSexo] = useState("");
    const [nascimento, setNascimento] = useState("");
    const [cpf, setCpf] = useState("");
    const [nacionalidade, setNacionalidade] = useState("");

    const [listaAcompanhante, setlistaAcompanhante] = useState([]);

    const [escolaridade, setEscolaridade] = useState("");

    const [parentesco, setParentesco] = useState("");


    const [tipoIdAcomp, setTipoIdAcomp] = useState("cpf");

    const [tipoAcomp, setTipoAcomp] = useState("Esposo(a)");

    const [ telefoneacomp, setTelefoneAcomp] = useState("");

    const [emailacomp, setEmailAcomp] = useState("");

    const handleAddLista = () => {

        const index = listaAcompanhante.findIndex(item => item.cpf === cpf);

        if (index !== -1) {
            listaAcompanhante[index].nome = nome;
            listaAcompanhante[index].sobrenome = sobrenome;
            listaAcompanhante[index].sexo = sexo;
            listaAcompanhante[index].data_nascimento = nascimento;
            listaAcompanhante[index].cpf = cpf;
            listaAcompanhante[index].nacionalidade = nacionalidade;
            listaAcompanhante[index].parentesco = parentesco;
            listaAcompanhante[index].escolaridade = escolaridade;
            listaAcompanhante[index].tipoIdAcomp = tipoIdAcomp;
            listaAcompanhante[index].tipoAcomp = tipoAcomp;
            listaAcompanhante[index].emailAcomp = emailacomp;
            listaAcompanhante[index].telefoneAcomp = telefoneacomp;


        } else {
            listaAcompanhante.push({
                nome: nome,
                sobrenome: sobrenome,
                sexo: sexo,
                data_nascimento: nascimento,
                cpf: cpf,
                nacionalidade: nacionalidade,
                parentesco: parentesco,
                escolaridade: escolaridade,
                tipoIdAcomp: tipoIdAcomp,
                tipoAcomp: tipoAcomp,
                emailAcomp: emailacomp,
                telefoneAcomp: telefoneacomp

            });
        }

        localStorage.setItem("@cadastro:Etapa3", JSON.stringify(listaAcompanhante))
        setNome("");
        setSobrenome("");
        setSexo("");
        setNascimento("");
        setCpf("");
        setNacionalidade("");
        setParentesco("");
        setEscolaridade("");
        setTipoIdAcomp("");
        setEmailAcomp("");
        setTelefoneAcomp("");
        setTipoAcomp("");

    };

    const opcoes = [
        { key: 1, value: 'Sem Escolaridade' },
        { key: 2, value: 'Ensino Fundamental' },
        { key: 3, value: 'Ensino Medio' },
        { key: 4, value: 'Ensino Superior' },
        { key: 5, value: 'Graduado' },
        { key: 6, value: 'Mestrado' },
        { key: 7, value: 'Doutorado' }
    ];

    const opcoes2 = [
        { key: 1, value: "Avô(ó)" },
        { key: 2, value: "Esposo(a)" },
        { key: 3, value: "Filho(a)" },
        { key: 4, value: "Irmão(ã)" },
        { key: 5, value: "Mãe" },
        { key: 6, value: "Pai" },
        { key: 7, value: "Tio(a)" }
    ];

    const opcoes3 = [
        { key: 1, value: 'Brasileiro(a)' },
        { key: 2, value: 'Estrangeiro(a)' },
    ];


    const handleOptionChange = (e) => {
        setSexo(e.target.value);
    }

    const handlealterar = (e) => {
        const cpf = e.target.value;
        for (let index = 0; index < listaAcompanhante.length; index++) {
            const element = listaAcompanhante[index];
            if (element.cpf === cpf) {
                setNome(element.nome);
                setSobrenome(element.sobrenome);
                setSexo(element.sexo);
                setNascimento(element.data_nascimento);
                setCpf(element.cpf);
                setNacionalidade(element.nacionalidade);
                setParentesco(element.parentesco);
                setEscolaridade(element.escolaridade);
                setTipoIdAcomp(element.tipoIdentificacao);
                setTipoAcomp(element.tipoAcomp);
                setEmailAcomp(element.emailAcomp);
                setTelefoneAcomp(element.telefoneAcomp);

            }
        }
    }



    const handleExcluir = () => {
        const index = listaAcompanhante.findIndex(item => item.cpf === cpf);
        if (index !== -1) {
            listaAcompanhante.splice(index, 1);

            localStorage.setItem("@cadastro:Etapa3", JSON.stringify(listaAcompanhante))
            setNome("");
            setSobrenome("");
            setSexo("");
            setNascimento("");
            setCpf("");
            setNacionalidade("");
            setParentesco("");
            setEscolaridade("");
            setTipoIdAcomp("");
            setEmailAcomp("");
            setTelefoneAcomp("");
            setTipoAcomp("");
        }
    }


    useEffect(() => {
        const interval = setInterval(() => {
            (async () => {
                const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa3"));
                if (dados != null) {
                    setlistaAcompanhante(dados);
                }
            })();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className=".containercadastro" style={{ alignItems: 'center' }} onSubmit={() => (listaAcompanhante.length === 0 || responsavel) ? navigation("/contato") : alert("É Preciso que marque a opção de responsabilidade sobre os acompanhantes.")}>
                <div>
                    <div className="text1-black">Acompanhantes</div>

                    <div className="corpoacom ">

                        <div>
                            <div className="titleinput">Documentação</div>
                            <div className="col-2 flex">
                                <div>
                                    <input type="radio" value="Esposo(a)" name="ident2" checked={tipoAcomp === "Esposo(a)"} onChange={item => setTipoAcomp(item.target.value)} />
                                    <label className="textRadio">Esposo(a)</label>
                                </div>
                                <div>
                                    <input type="radio" value="Filho(s)" name="ident2" checked={tipoAcomp === "Filho(s)"} onChange={item => setTipoAcomp(item.target.value)} />
                                    <label className="textRadio">Filho(s)</label>
                                </div>
                            </div>
                            <div className="col-2 flex">
                                <div>
                                    <input type="radio" value="cpf" name="ident" checked={tipoIdAcomp === "cpf"} onChange={item => setTipoIdAcomp(item.target.value)} />
                                    <label className="textRadio">CPF</label>
                                </div>
                                <div>
                                    <input type="radio" value="passaporte" name="ident" checked={tipoIdAcomp === "passaporte"} onChange={item => setTipoIdAcomp(item.target.value)} />
                                    <label className="textRadio">Passaporte</label>
                                </div>
                            </div>

                            


                        </div>

                        <div className="containerbloco" class="col-5 flex">

                            <div>
                                {tipoIdAcomp === "cpf" &&

                                    <InputMask
                                        title="CPF"
                                        className="cpf-input"
                                        mask="999.999.999-99"
                                        maskChar="_"
                                        type="text"
                                        id="acompanhanteCPF"
                                        style={{
                                            fontFamily: "Poppins-Regular",
                                            height: '5.6rem',
                                            width: '97%',
                                            marginBottom: '2rem',
                                            border: '1px solid #b0b0b0',
                                            borderRadius: '5.7rem',
                                            color: '#666666',
                                            fontSize: '1.6rem',
                                            paddingLeft: '1rem',
                                        }}
                                        name="cpf"
                                        value={cpf}
                                        onChange={event => setCpf(event.target.value)}
                                    />

                                }

                                {tipoIdAcomp === "passaporte" &&
                                    <InputText
                                        // id= "visitanteCPF"

                                        // title="CPF"
                                        placeholder="Número do passaporte"
                                        value={cpf}
                                        onchange={item => setCpf(item.target.value)}
                                        msnWarning="Campo precisa ser preenchido"
                                        required={true}
                                    />
                                }
                            </div>

                            <Dropdown
                                data={opcoes3}
                                placeholder="Nacionalidade"
                                onchange={item => setNacionalidade(item.target.value)}
                                value={nacionalidade}
                                msnWarning="Campo obrigatório."
                                required={true}
                            />


                        </div>

                        
                        

                        <div className="containerbloco" class="col-5 flex">
                            <InputText
                                title="Nome"
                                placeholder="Primeiro nome"
                                value={nome}
                                onchange={item => setNome(item.target.value)}
                                msnWarning="Obrigatório preencher o primeiro nome."
                            />

                            <InputText
                                title="Sobrenome"
                                placeholder="Segundo nome"
                                value={sobrenome}
                                onchange={item => setSobrenome(item.target.value)}
                                msnWarning="Obrigatório preencher o segundo nome"
                            />

                        </div>



                        <div className="containerbloco" class="col-5 flex">


                            <div>

                                <div className="textInput">Data de nascimento</div>
                                <InputText
                                    type="date"
                                    value={nascimento}
                                    onchange={(item) => setNascimento(item.target.value)}
                                    msnWarning="Campo precisa ser preenchido."
                                />

                            </div>

                            <div>

                                <div className="textInput">telefone(whatsapp)</div>
                                < InputText
                                    placeholder=""
                                    value={telefoneacomp}
                                    onchange={item => setTelefoneAcomp(item.target.value)}
                                    msnWarning="Obrigatório preencher o primeiro nome."
                                />

                            </div>

                        </div>

                        <div>
                            <InputText
                                title="E-mail (ao digitar seu email, o sistema criara uma conta de acesso na sllips)"
                                placeholder="Digite o E-mail"
                                value={emailacomp}
                                onchange={item => setEmailAcomp(item.target.value)}
                                required={false}
                            />
                        </div>


                    </div>


                    <div className="btngroup" >
                        <button className="btn deletar" type="button" onClick={() => handleExcluir()}>Deletar</button>
                        <button className="btn salvar" type="button" onClick={() => handleAddLista()}>Adicionar</button>
                    </div>

                </div>

                
                <div className="containerbloco" >
                    <div className="linhadivisor" />

                    <div className="titleinput" >Acompanhante(s)</div>

                    {listaAcompanhante.map((item, index) => (
                        // <input key={index} className="input" type="text" value={item.nome} disabled />
                        <div className="listaAcompanhante flex" key={item.cpf}>
                            <input type="radio" name="lista" value={item.cpf} onChange={handlealterar} />
                            <label className="textInput maiuscula">{item.nome} {item.sobrenome} | {item.cpf}</label>
                        </div>
                    ))}

                </div>

            </div>
        </div>
    )
}
export default CadastroAcompanhante;
