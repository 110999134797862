import Dropdown from "../../components/Dropdown";
import Rodape from "../../components/Rodape";
import InputText from "../../components/InputText";
import { useState, useEffect } from "react";
import LogoColor from "../../assets/image/logo_color.svg";
import DropdownCity from "../../components/DropdownCity";
import DropdownUf from "../../components/DropdownUf";
import API from "../../service/api";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import CadastroAcompanhante from "../CadastroAcompanhante";
import validaCampos from "../../components/ValidaCampos";

const CadastroAcomTrans = () => {
  const navigation = useNavigate();

  const [acomodacao, setAcomodacao] = useState("");
  const [nomeAcomodacao, setNomeAcomodacao] = useState("");
  const [veiculo, setVeiculo] = useState("");
  // const [placa, setPlaca] = useState("");

  const [estadouf, setEstadoUF] = useState("");
  const [estadoufh, setEstadoUFH] = useState("");
  const [pais, setPais] = useState("");

  const [cidade, setCidade] = useState("");

  const [hospedagem, setHospedagem] = useState(null);

  const [cadastro, setCadastro] = useState(null);

  const [dataCidade, setDataCidade] = useState("");
  const [dataUf, setDataUf] = useState("");
  const [selectedUf, setSelectedUf] = useState("0");

  const [telhospedagem, setTelHospedagem] = useState("");
  const [endereco, setEndereco] = useState("");

  const [localreserva, setLocalreserva] = useState("descontinuado");

  const [id_municipio, setIdmunicipio] = useState(0);

  const [agencia, setAgencia] = useState("");

  const [tipoTransporte, setTipoTransporte] = useState("");

  const [placaOnibus, setPlacaOnibus] = useState([]);

  const [compartilhaDados, setCompartilhaDados] = useState(true);

  const [tipoEmbarque, setTipoEmbarque] = useState("Passageiro");

  const [nomeEmpEmbarque, setNomeEmpEmbarque] = useState("");

  const [PetFriendly, setPetFriendly] = useState("1");

  const [tipoTurismo, setTipoTurismo] = useState("");

  const [tiporoteiro, setTipoRoteiro] = useState("");

  const [mensagem, setMensagem] = useState("");

  const localReserva = [
    { key: 3, value: "Airbnb" },
    { key: 8, value: "Alugue Temporada" },
    { key: 1, value: "Booking" },
    { key: 4, value: "Expedia" },
    { key: 5, value: "Hotel Urbano - Hurb" },
    { key: 7, value: "Kayak" },
    { key: 12, value: "Reserva direta com hospedagem" },
    { key: 12, value: "Reserva no balcão" },
    { key: 6, value: "TripAdvisor" },
    { key: 9, value: "Tripping" },
    { key: 2, value: "Trivago" },
    { key: 10, value: "Troca Casa" },
    { key: 11, value: "Outros" },
  ];

  const tipo = [
    { key: 1, value: "Apartamento" },
    { key: 2, value: "Camping" },
    { key: 3, value: "Casa Temporada" },
    { key: 4, value: "Chalé" },
    { key: 5, value: "Hotel" },
    { key: 6, value: "Hostel" },
    { key: 7, value: "Pousada" },
    { key: 8, value: "Rancho" },
  ];

  const tipoVeiculo = [
    { key: 1, value: "Aéreo" },
    { key: 2, value: "Automóvel" },
    { key: 3, value: "Motocicleta" },
    { key: 4, value: "MotorHome" },
    { key: 5, value: "Ônibus de Excursão" },
    { key: 6, value: "Transporte Rodoviário" },
  ];

  const tipoVeiculoAgencia = [
    { key: 5, value: "Onibus de Excursão" },
    { key: 6, value: "Transporte Rodoviário" },
  ];

  useEffect(() => {

    const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa5"));

    const Hospedagem = JSON.parse(localStorage.getItem("@passagem:Etapa"));

    const cadastroNovo = JSON.parse(localStorage.getItem("@passagem:Etapa0"));

    const IdMunicipio = localStorage.getItem("id_municipio");

    const cadAgencia = localStorage.getItem("@agencia:Etapa2");

    if (IdMunicipio) {
      setIdmunicipio(IdMunicipio);
    }

    if (Hospedagem != null) {
      setHospedagem(Hospedagem.passagem);
    }

    if (cadastroNovo != null) {
      setCadastro(cadastroNovo.cadastro);
    }

    if (dados != null) {
      setEstadoUF(dados.estadouf);
      setEstadoUFH(dados.estadoufh);
      setPais(dados.pais);

      setCidade(dados.cidade);

      setAcomodacao(dados.acomodacao);
      setNomeAcomodacao(dados.nomeAcomodacao);
      setVeiculo(dados.veiculo);
      //setPlaca(dados.placa);

      setTelHospedagem(dados.telhospedagem);
      setEndereco(dados.endereco);
    }

    if (cadAgencia) {
      setAgencia(cadAgencia);
      if (cadAgencia.length > 0) {
        setTipoTransporte(tipoVeiculoAgencia);
      }
    } else {
      setTipoTransporte(tipoVeiculo);
    }
  }, []);

  const TelefoneMask = (value, info) => {
    if (value.length <= 17) {
      setTelHospedagem(value);
    }
  };

  //////////////////////// Rotina buscar cidade e estado //////////////////////////

  useEffect(() => {
    if (selectedUf === "0") {
      return;
    }
    try {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`
        )
        .then((response) => {
          setDataCidade(response.data);
        });
    } catch (err) {
      console.log("Erro ao buscar dados da api: ", err);
    }
  }, [selectedUf]);

  useEffect(() => {
    //pega os dados salvos na pagina anterior
    const aux = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));

    //atribui o valor do tipo turismo na pagina anterior a variavel local
    setTipoTurismo(aux.tipo_turismo);

    //atribui o valor do tipo turismo na pagina anterior a variavel local
    setTipoRoteiro(aux.tipo_roteiro);
  }, []);

  useEffect(() => {
    //pega os dados salvos na pagina anterior
    const aux = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));

    //atribui o valor do tipo turismo na pagina anterior a variavel local
    setTipoRoteiro(aux.tipo_roteiro);
  
  }, []);

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/")
      .then((response) => {
        setDataUf(response.data);
      });
  }, [pais]);

  function handleSelectUf(value) {
    setSelectedUf(value);
    setEstadoUF(value);
  }

  function handleSelectCity(value) {
    setCidade(value);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////


 //  tipo_turismo: 'Turismo de Excursão'

  async function handleSalvarCadastro(event) {
    event.preventDefault();

    localStorage.setItem(
      "@cadastro:Etapa5",
      JSON.stringify({
        pais,
        estadouf,
        estadoufh,
        cidade,
        acomodacao : hospedagem,
        nomeAcomodacao,
        telhospedagem,
        endereco,
        localreserva,
        tipo_turismo: tipoTurismo,
        tipo_roteiro: tiporoteiro,
        aceita_pet: PetFriendly
      })
    );

    const etapaP = await JSON.parse(localStorage.getItem("@passagem:Etapa"));
    const etapa1 = await JSON.parse(localStorage.getItem("@cadastro:Etapa1"));
    const etapa2 = await JSON.parse(localStorage.getItem("@cadastro:Etapa2"));
    const etapa3 = await JSON.parse(localStorage.getItem("@cadastro:Etapa3"));
    const etapa4 = await JSON.parse(localStorage.getItem("@cadastro:Etapa4"));
    const etapa5 = await JSON.parse(localStorage.getItem("@cadastro:Etapa5"));

    const data = {
      id_municipio: id_municipio,
      tipo_turismo: etapa1.tipo_turismo,
      viajando_com: etapa1.viajando_com,
      conhece_municipio: etapa1.conhece_municipio,
      data_chegada: etapa1.data_chegada,
      data_partida: etapa1.data_partida,
      uf_hospedado: etapa1.uf_hospedado ||'',
      cidade_hospedado: etapa1.cidade_hospedado || '',
      aceita_pet: PetFriendly,
      visitante: {
        nome: etapa2.visitante.nome,
        sobrenome: etapa2.visitante.sobrenome,
        tipoIdentificacao: etapa2.visitante.tipoIdentificacao,
        identificacao: etapa2.visitante.identificacao,
        data_nascimento: etapa2.visitante.data_nascimento,
        sexo: etapa2.visitante.sexo,
        escolaridade: etapa2.visitante.escolaridade,
        compartilha_dados: etapa2.visitante.compartilha_dados,
        pais: etapa2.visitante.pais,
        cidade: etapa2.visitante.cidade,
        estado: etapa2.visitante.estado,
        email: etapa2.visitante.email,
        telefone: etapa2.visitante.telefone,
        nacionalidade: etapa2.visitante.nacionalidade,
        password: etapa2.visitante.password,
      },
      acompanhante: etapa3,
      acomodacao: {
        tipo: hospedagem || 'Vazio',
        nome: nomeAcomodacao || 'Vazio',
      },
      transporte: {
        tipo: etapa2.visitante.veiculo,
        placa: etapa2.visitante.placa,
      },
      emergencia: {
        nome: "etapa4.nome",
        parentesco: "etapa4.parentesco",
        telefone: "etapa4.emergenciaTel",
      },
      passagem: {
        hospedado_outro_municipio: tiporoteiro === 'Estou hospedado em outro município' ? true : false,
        nao_hospedado: tiporoteiro === 'Estou hospedado em outro município' ? false : true ,
      },
      pet: {
        aceita_pet: PetFriendly,
      },
      local_reserva: localreserva,
      agencia: agencia,
      tipo_turismo: tipoTurismo,
      tipo_roteiro: tiporoteiro 
    }

    setMensagem("");

    validaCampos.validate(data)
      .then((valid) =>{
        API.post("/viagemNew", data).then((result) => {
            //localStorage.clear();
            navigation(`/concluido/${result.data.newViagem._id}/${result.data.newViagem.data_chegada}/${result.data.newViagem.data_partida}`)
        }).catch((error) => {
            alert(error.message);
        })


      })
      .catch((err) =>{
        setMensagem(err.message);
        console.log("Erro: ", err.message);
    });

  
  }

  return (
    <form class="corpoform flex">
      <section class="corpo flex">
        
      <div>
            
      {tiporoteiro && tiporoteiro !== "Estou só de passagem" && (
          <>
            <div className="text1-black">Hospedagem</div>
            <div className="col-5 flex">
              <div>
                <div className="textInput">Tipo de Hospedagem</div>

                <Dropdown
                  data={tipo}
                  placeholder=""
                  msnWarning="Informe o tipo de hospedagem utilizado."
                  value={hospedagem}
                  onchange={(item) => setAcomodacao(item.target.value)} 
                  required={true}
                />
              </div>

              <InputText
                title="Nome da Hospedagem"
                placeholder="Ex: Hotel ..., Pousada...,"
                value={nomeAcomodacao}
                onchange={(item) => setNomeAcomodacao(item.target.value)}
                msnWarning="Informe o nome da hospedagem"
                required={true}
              />
            </div>
          </>
        )}



          </div>

          <div class="col-5 flex">

            <div className="containerbloco">
              <div className="textInput">
                Qual tipo de hospedagem você tem preferência?
              </div>

              <div className="col-2 flex">
                <div>
                  <input
                    type="radio"
                    value="1"
                    name="petID"
                    checked={PetFriendly === "1"}
                    onChange={(item) => setPetFriendly(item.target.value)}
                  />
                  <label className="textRadio">Que aceite Pet friendly</label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="0"
                    name="petID"
                    checked={PetFriendly === "0"}
                    onChange={(item) => setPetFriendly(item.target.value)}
                  />
                  <label className="textRadio">
                    Que não aceite Pet friendly
                  </label>
                </div>
              </div>
            </div>
          </div>

        {tipoTurismo === "Turismo de Excursão" && (
          <>
            <div className="text1-black">Informações de embarque</div>
            <div className="containerbloco">
              <div>
                <div className="textInput">Na viagem você é:</div>

                <div className="col-2 flex">
                  <div>
                    <input
                      type="radio"
                      value="Passageiro"
                      name="embarqID"
                      checked={tipoEmbarque === "Passageiro"}
                      onChange={(item) => setTipoEmbarque(item.target.value)}
                    />
                    <label className="textRadio">Passageiro</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="Guia Turistico"
                      name="embarqID"
                      checked={tipoEmbarque === "Guia Turistico"}
                      onChange={(item) => setTipoEmbarque(item.target.value)}
                    />
                    <label className="textRadio">Guia Turistico</label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      value="Motorista"
                      name="embarqID"
                      checked={tipoEmbarque === "Motorista"}
                      onChange={(item) => setTipoEmbarque(item.target.value)}
                    />
                    <label className="textRadio">Motorista</label>
                  </div>
                </div>
              </div>

              <InputText
                title="Nome da Empresa"
                value={nomeEmpEmbarque}
                onchange={(item) => setNomeEmpEmbarque(item.target.value)}
                required={false}
              />
            </div>
          </>
        )}

        <div className="containerbloco">
          <CadastroAcompanhante />
        </div>

        <div className="containerPolitica flex">
          <input
            type="checkbox"
            className="checkbox"
            checked={compartilhaDados}
            onChange={(item) => setCompartilhaDados(item.target.checked)}
          />
          <div>
            <div className="politica">
              Compartilhar meus dados de registro com nossos provedores de
              conteúdo para fins de analíses turísticas.
            </div>
            <div className="politica">
              <br />
              Ao preencher os dados, você concorda com os{" "}
              <Link className="a" to={"/TermosdeUso"}>
                <strong> Termos de uso</strong>
              </Link>
              ,
              <Link className="a" to={""}>
                <strong>Termos do Município</strong>
              </Link>{" "}
              e{" "}
              <Link className="a" to={"/PoliticaDePrivacidade"}>
                <strong>Política de Privacidade.</strong>
              </Link>
            </div>
          </div>
        </div>

        <div >

          <button className="btncadastrar " style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}} onClick={handleSalvarCadastro}>
              Salvar Cadastro
          </button>

          <div className="text2" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'red', fontWeight: 'bold'}}>{mensagem}</div>

        </div>

      </section>
    </form>
  );
};

export default CadastroAcomTrans;
