import { useEffect, useRef, useState } from "react";
import Logobanner from "../../assets/image/logobanner.svg";
import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Rodape from "../rodape";
import { BuscaClientes_Cidades } from "../../models/buscaCidades";
import { useAuth } from "../../service/JwtContext";
import { autentication } from "../../utils/config";
import BtnTipoCadastro from "../../components/BtnTipoCadastro";
import { FiMenu, FiX, FiLogOut, FiLogIn  } from 'react-icons/fi'
import InputText from "../../components/InputText";
import API  from "../../service/api";
import { useNavigate } from "react-router-dom";
import { StatusBar } from "../../components/StatusBar";

const dataFeeds = [
    {
        id: 1,
        nome: 'Wesley Leno',
        descricao: 'Expetacular, facilita muito a sua viagem',
        nome_imagem: 'avatar1'
    },
    {
        id: 2,
        nome: 'Ezequiel Alef',
        descricao: 'Me ajudou muito no momento de embarcar em minha viagem',
        nome_imagem: 'avatar2'
    },
    {
        id: 3,
        nome: 'Ana Cláudia',
        descricao: 'A agilidade na hodra de embarcar em minha viagem',
        nome_imagem: 'avatar3'
    }
]

const Home = () => {

    const [ cidades, setCidades] = useState();
    const [ cadastrado, setCadastrado ] = useState(false);
    const [ parceiros , setParceiros ] = useState([]);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isOpenlogin, setIsOpenLogin ] = useState(false);
    const [ autenticado, setAutenticado ] = useState(false);
    const [ nome , setNome ] = useState('Wellington Borges');
    const [ login , setLogin ] = useState(false);
    const [ email , setEmail ] = useState('');
    const [ password , setPassword ] = useState('');
    const [ message , setMessage ] = useState('');
    const [feeds, setFeeds] = useState(dataFeeds);

    const carousel = useRef();
    const [width, setwidth] = useState(0);

    const { auth , user } = useAuth();
    const navigation = useNavigate();



    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setLogin(false);
    };

    const handleLogin = () => {
        setIsOpenLogin(!isOpenlogin);
    };

    const AutenticacaoWebSite = async () =>{
        try{
            await auth.login(autentication.login, autentication.password);
        }catch(e){
            console.log("Deu erro", e);
        }
    }

    // async function handleAvancaLogin(e){
    //     e.preventDefault();
    //     setMessage('');

    //     try{

    //         //const { data : response } = await API.get(`/visitantes/${email}/${password}`);

    //         const { data : response } = await auth.login(autentication.login, autentication.password)

    //         if(user){
    //             if(user._id > 0){
    //                 setLogin(false);
    //                 navigation("/destino");
    //             }
    //             else{
    //                 setMessage(response?.message);
    //             }
                
    //         }else{
    //             console.log("Login efetuado ", response);
    //             navigation("/destino");
    //             setMessage(response);
    //             setLogin(true);

    //         }

    //     }catch(error){
    //         console.log("Falaha: ", error);

    //     }

        
    // }

    useEffect(() => {
        setwidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth);

        const dados = JSON.parse(localStorage.getItem("@perfil:Atualizar"));

        if (dados != null) {

            setEmail(dados.email);
            setPassword(dados.password);

        }

        const buscaCidades = async () =>{
            
            const  res  = await BuscaClientes_Cidades();

            if(res){
                setCidades(res);
            }
        };

        //AutenticacaoWebSite();

        buscaCidades();

    }, [])




    return (

        <section className="container">

            <StatusBar openlogin={isOpenlogin}/>

            <section className="home_topo">

            <div className="home_topo_conteudo2">
                    <img src={LogoColor} alt="logo" className="logoColor" />
                    <img src={Logobanner} alt="logobanner" className="logobanner" />
                    <div className="titulo">
                    <strong className="negrito">sllips.</strong> Destino seguro, <br /> experiência completa!</div>
                    <label className="subtitulo">Viva várias experiências com um único cadastro!</label>
                </div>
 
            </section>


            <div className="texthome">Facilite sua viagem com um único cadastro!</div>


            <div className="container-info">
                <div className="corpo2-info">
                    <BtnTipoCadastro
                        onclick={() => navigation("/Destino/")}
                        title="Primeiro cadastro na plataforma Sllips"

                        value={!cadastrado}
                        onchange={item => console.log(item.target.value)}

                        descricao={
                            <>
                                Caso seja seu primeiro cadastro na Sllips,<span className="descricaoDestaque">utilize esta opção para atualizar seus dados novamente!</span> 
                            </>
                        }
                />
                </div>
                
                <div className="corpo2-info">
                    <BtnTipoCadastro
                        // onclick={() => navigation("/Novo")}
                        onclick={() => handleLogin()}
                        title="Já fez seu cadastro? Agora é só efetuar  o login"

                        value={!!cadastrado}
                        onchange={handleLogin}


                        descricao={
                            <>
                                Caso já tenha efetuado o cadastro na sllips,<span className="descricaoDestaque">utilize esta opção para atualizar seus dados novamente!</span> 
                            </>
                        }
                    />
                </div>
            </div>

            <div className="titulodepoimento">O que os clientes dizem sobre nós!</div>

            <div className="depoimento">
                
                { feeds &&
                    <div>
                        <motion.div ref={carousel} className="carousel" whileTap={{ cursor: "grabbing" }}>
                            
                            <motion.div
                                drag="x"
                                dragConstraints={{ right: 0, left: -width }}
                                className="inner flex">

                                {feeds.map(item => (
                                <Link className="box" >
                                    <motion.div className="item" key={item.id}>

                                    <div className="corpo-depoimento">
                                        
                                        <div className="btnTpDepoimento">
                                            <div 
                                                className="Tab1" 
                                                style={{ 
                                                minWidth: "35%", 
                                                minHeight: "100px", 
                                                display: "flex", 
                                                flexDirection: "column", 
                                                justifyContent: "center", 
                                                alignItems: "center", 
                                                }}
                                            >
                                                {/* Imagem pequena centralizada, saindo da parte superior */}
                                                <img 
                                                src={`/imagens/avatar/${item.nome_imagem}.jpeg`} 
                                                alt="Imagem pequena" 
                                                style={{ 
                                                    width: "60px", 
                                                    height: "60px", 
                                                    borderRadius: "50%", 
                                                    position: "absolute", 
                                                    top: "-30px" // Metade da altura da imagem para que ela fique no topo
                                                }} 
                                                />

                                                <div className="btnTitulo" 
                                                    style={{ 
                                                        display: "flex", 
                                                        justifyContent: "center", 
                                 
                                                        width: "100%", 
                                                        height: "60px",  
                                                        position: "absolute", 
                                                        paddingTop: "10px", dingTop: "10px", dingTop: "5px",
                                                        // Metade da altura da imagem para que ela fique no topo
                                                    }} 
                                                >
                                                    {item.nome}
                                                </div>

                                                {/* Título e descrição */}
                                                
                                                <div className="btnDescricao" style={{ marginTop: "50px" }}>
                                                   { item.descricao}   
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                        
                                            {/* <img src={`/imagens/${item.nome_imagem}.png`} alt="Imagem" /> */}
                                            
                                        
                                        
                                    </motion.div>
                                </Link>
                                ))}

                            </motion.div>
                        </motion.div>
                    </div>
                }
    
            </div>

            {
                parceiros.length > 0 &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>  
                    <div className="text1" style={{ fontSize: "20px" }}>Nossos parceiros</div>
                </div>

            }


          <Rodape />


        </section>
    )
}
export default Home;

