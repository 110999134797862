import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo_color.svg";
import Dropdown from "../../components/Dropdown";
import { useEffect, useRef, useState } from "react";
import Rodape from "../../components/Rodape";
import InputText from "../../components/InputText";
import IMask from "imask";
import InputMask from "react-input-mask";
import bcrypt from "bcryptjs";

import LogoColor from "../../assets/image/logo_color.svg";
import ImageUploader from "../../components/DropdownZone";
import PasswordInput from "../../components/PasswordInput";
import { BuscaVeiculoAgenciaByCNPJ } from "../../models/buscaAgencia";
import DropdownAgencia from "../../components/DropdownVeiculo";
import CadastroAcompanhante from "../CadastroAcompanhante";
import CadastroAcomTrans from "../CadastroAcomTrans";
import { BuscaEstados } from "../../models/buscaEstados";
import DropdownEstadoUF from "../../components/DropdownEstadoUF";
import { BuscaMunicipios } from "../../models/buscaMunicipios";

const CadastroVisitante = () => {
  const navigation = useNavigate();

  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [identificacao, setIdentificacao] = useState("");
  const [tipoIdentificacao, setTipoIdentificacao] = useState("cpf");
  const [sexo, setSexo] = useState("");
  const [escolaridade, setEscolaridade] = useState("");
  const [compartilhaDados, setCompartilhaDados] = useState(true);
  const [nascimento, setNascimento] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [veiculoAgencia, setVeiculoAgencia] = useState([]);

  const [cnpj, setCnpj] = useState(null);

  const [tipoTurismo, setTipoTurismo] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [veículoempresa, setVeículoEmpresa] = useState("");

  const [embarque, setEmbarque] = useState("");

  const [pais, setPais] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [telefone, setTelefone] = useState("");
  const [veiculo, setVeiculo] = useState("");

  const [dataEstado, setDataEstado] = useState([]);
  const [dataCidade, setDataCidade] = useState([]);
  const [placa, setPlaca] = useState("");

  const tipoVeiculo = [
    { key: 1, value: "Aéreo" },
    { key: 2, value: "Automóvel" },
    { key: 3, value: "Motocicleta" },
    { key: 4, value: "MotorHome" },
    { key: 5, value: "Ônibus de Excursão" },
    { key: 6, value: "Transporte Rodoviário" },
  ];


  useEffect(() => {
    localStorage.setItem(
      "@cadastro:Etapa2",
      JSON.stringify({
        visitante: {
          nome: nome,
          sobrenome: sobrenome,
          nacionalidade,
          tipoIdentificacao,
          identificacao,
          sexo,
          data_nascimento: nascimento,
          escolaridade,
          compartilha_dados: compartilhaDados,
          email,
          password: newpassword,
          empresa,
          cnpj,
          veículo_empresa: veículoempresa,
          tipo_turismo: tipoTurismo,
          embarque: embarque,
          veiculo: veiculo,
          placa: placa,
          pais,
          estado,
          cidade,
          telefone
        },
      })
    );
   }, [nome,sobrenome,nacionalidade,tipoIdentificacao, nascimento, identificacao,sexo,escolaridade,compartilhaDados,email,newpassword,empresa,cnpj,veiculo, placa, pais, cidade, estado,telefone]);

  useEffect(() => {
    const fetchdata = async () => {
      setVeiculoAgencia([]);
      const res = await BuscaVeiculoAgenciaByCNPJ(cnpj);

      if (res.veiculo.length > 0) {
        setVeiculoAgencia(res.veiculo);
      }
    };
    if (cnpj && cnpj.length > 11) {
      fetchdata();
    }
  }, [cnpj]);

  useEffect(() => {
    const fetchdata = async () => {
      const res = await BuscaEstados();
      if (res.length > 0) {
        setDataEstado(res);
      }
    };
    fetchdata();
  }, []);

  useEffect(() => {
    const fetchdata = async () => {
      const res = await BuscaMunicipios(estado);
      if (res.length > 0) {
        setDataCidade(res);
      }
    };
    if (estado && estado.length > 0) {
      fetchdata();
    }
  }, [estado]);

  const handleVoltar = async () => {
    try {
      if (compartilhaDados) {
        localStorage.setItem(
          "@cadastro:Etapa2",
          JSON.stringify({
            visitante: {
              nome: nome,
              sobrenome,
              nacionalidade,
              tipoIdentificacao,
              identificacao,
              sexo,
              data_nascimento: nascimento,
              escolaridade,
              compartilha_dados: compartilhaDados,
              email,
              password: newpassword,
              empresa,
              cnpj,
              veículo_empresa: veículoempresa,
              tipo_turismo: tipoTurismo,
              embarque: embarque,
              veiculo: veiculo,
              placa: placa,
              pais,
              estado,
              cidade, 
              telefone
            },
          })
        );

        navigation(-1);
      } else {
        alert("Aceite os Termos e Condições para prosseguir.");
      }
    } catch (error) {
      console.log("caiu no erro: ", error);
    }
  };



  useEffect(() => {
    //pega os dados salvos na pagina anterior
    const aux = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));

    //atribui o valor do tipo turismo na pagina anterior a variavel local
    setTipoTurismo(aux.tipo_turismo);
  }, []);

  const opcoes = [
    { key: 1, value: "Sem Escolaridade" },
    { key: 2, value: "Ensino Fundamental" },
    { key: 3, value: "Ensino Medio" },
    { key: 4, value: "Ensino Superior" },
    { key: 5, value: "Graduado" },
    { key: 6, value: "Mestrado" },
    { key: 7, value: "Doutorado" },
  ];

  const opcoes2 = [
    { key: 1, value: "Onibus 1" },
    { key: 2, value: "Onibus 2" },
    { key: 3, value: "Onibus 3" },
    { key: 4, value: "Onibus 4" },
  ];

  const opcoes3 = [
    { key: 1, value: "Brasileiro(a)" },
    { key: 2, value: "Estrangeiro(a)" },
  ];

  const maskOptions = {
    mask: "000.000.000-00",
  };
  const maskRef = useRef(null);

  useEffect(() => {
    if (tipoIdentificacao == "cpf") {
      maskRef.current = IMask(
        document.getElementById("visitanteCPF"),
        maskOptions
      );
    }
  }, [tipoIdentificacao]);

  const maskOptionsCnpj = {
    mask: "00.000.000/0000-00",
  };

  useEffect(() => {
    if (cnpj) {
      maskRef.current = IMask(
        document.getElementById("visitanteCNPJ"),
        maskOptionsCnpj
      );
    }
  }, [cnpj]);

  return (
    <form className="container" onSubmit={handleVoltar}>
      <section className="home_topo_conteudo">
        <img src={LogoColor} alt="logo" className="logoColor" />
        <img src={LogoColor} alt="logo" className="logo" />
      </section>

      <section class="corpo flex">
        <div className="text1-black">Cadastro do visitante</div>
        <div className="text2">
          Preencha os dados do perfil. Isso levará alguns segundos.
          <br />
          <strong>
            Depois ganhe tempo na visitação dos pontos turísticos!
          </strong>
        </div>

        {/* DOCUMENTAÇÂO */}

        <div className="containerbloco">
          <div className="titleinput">Documentação</div>

          <div className="col-2 flex">
            <div>
              <input
                type="radio"
                value="cpf"
                name="ident"
                checked={tipoIdentificacao === "cpf"}
                onChange={(item) => setTipoIdentificacao(item.target.value)}
              />
              <label className="textRadio">CPF</label>
            </div>
            <div>
              <input
                type="radio"
                value="passaporte"
                name="ident"
                checked={tipoIdentificacao === "passaporte"}
                onChange={(item) => setTipoIdentificacao(item.target.value)}
              />
              <label className="textRadio">Passaporte</label>
            </div>
          </div>

          <div className="containerbloco" class="col-5 flex">
            {tipoIdentificacao === "cpf" && (
              <div>
                <div className="textInput">CPF</div>
                <InputText
                  id="visitanteCPF"
                  placeholder="Número do CPF"
                  mask={maskOptions}
                  value={identificacao}
                  onchange={(event) => setIdentificacao(event.target.value)}
                  msnWarning="Campo precisa ser preenchido"
                  required={true}
                />
              </div>
            )}

            {tipoIdentificacao === "passaporte" && (
              <div>
                <div className="textInput">Passaporte</div>
                <InputText
                  placeholder="Número do passaporte"
                  value={identificacao}
                  onchange={(item) => setIdentificacao(item.target.value)}
                  msnWarning="Campo precisa ser preenchido"
                  required={true}
                />
              </div>
            )}

            <div>
              <div className="textInput">Nacionalidade</div>
              <Dropdown
                data={opcoes3}
                placeholder=""
                onchange={(item) => setNacionalidade(item.target.value)}
                value={nacionalidade}
                msnWarning="Campo obrigatório."
                required={true}
              />
            </div>
          </div>
        </div>

        <div className="col-5 flex">
          <InputText
            title="Nome"
            placeholder="Primeiro nome"
            value={nome}
            onchange={(item) => setNome(item.target.value)}
            msnWarning="Obrigatório preencher o primeiro nome."
            required={true}
          />

          <InputText
            title="Sobrenome"
            placeholder="Segundo nome"
            value={sobrenome}
            onchange={(item) => setSobrenome(item.target.value)}
            msnWarning="Obrigatório preencher o segundo nome"
            required={true}
          />
        </div>

        <div className="containerbloco">
          <div className="titleinput">Qual seu sexo</div>
          <div className="col-2 flex">
            <div>
              <input
                type="radio"
                value="Masculino"
                name="sexo"
                checked={sexo === "Masculino"}
                onChange={(item) => setSexo(item.target.value)}
              />
              <label className="textRadio">Masculino</label>
            </div>
            <div>
              <input
                type="radio"
                value="Feminino"
                name="sexo"
                checked={sexo === "Feminino"}
                onChange={(item) => setSexo(item.target.value)}
              />
              <label className="textRadio">Feminino</label>
            </div>
            <div>
              <input
                type="radio"
                value="Outros"
                name="sexo"
                checked={sexo === "Outros"}
                onChange={(item) => setSexo(item.target.value)}
              />
              <label className="textRadio">Outros</label>
            </div>
          </div>
        </div>

        <div className="containerbloco" class="col-5 flex">
          <InputText
            type="date"
            title="Data de nascimento"
            value={nascimento}
            onchange={(item) => setNascimento(item.target.value)}
            msnWarning="Informe a data de nascimento."
            required={true}
          />

          <div>
            <div className="textInput">Escolaridade</div>
            <Dropdown
              title="Escolaridade"
              data={opcoes}
              placeholder=""
              onchange={(item) => setEscolaridade(item.target.value)}
              value={escolaridade}
              msnWarning="Campo obrigatório."
              required={true}
            />
          </div>
        </div>

        {/*  Contato cliente */}

        <div className="text1-black">Contato</div>

        <div className="containerbloco" class="col-5 flex">
          <InputText
            title="País"
            placeholder="Digite o nome do país"
            value={pais}
            onchange={(item) => setPais(item.target.value)}
            msnWarning="Obrigatório preencher o país."
            required={true}
          />

          <div>
            <div className="textInput">Estado</div>

            <DropdownEstadoUF
              data={dataEstado}
              placeholder=""
              msnWarning="Selecione o Estado."
              value={estado}
              onchange={(item) => setEstado(item.target.value)}
              required={true}
            />
          </div>
        </div>

        <div className="containerbloco">
          {estado && (
            <div style={{ width: "100%" }}>
              <div className="textInput">Cidade</div>
              <DropdownEstadoUF
                data={dataCidade}
                placeholder=""
                msnWarning="Selecione a cidade"
                value={cidade}
                onchange={(item) => setCidade(item.target.value)}
                required={true}
              />
            </div>
          )}
        </div>

        <div className="containerbloco">
          {!estado && (
            <InputText
              title="Cidade"
              placeholder="Digite o nome da cidade"
              value={cidade}
              onchange={(item) => setCidade(item.target.value)}
              msnWarning="Obrigatório preencher a cidade"
              required={true}
              disabled={true}
            />
          )}

          <InputText
            title="Telefone (whatsapp)"
            placeholder="Digite o telefone"
            value={telefone}
            onchange={(item) => setTelefone(item.target.value)}
            msnWarning="Obrigatório preencher o telefone"
            required={true}
          />

          <InputText
            title="E-mail (ao digitar seu email, o sistema criara uma conta de acesso na sllips)"
            placeholder="Digite o E-mail"
            value={email}
            onchange={(item) => setEmail(item.target.value)}
            msnWarning="Obrigatório preencher o email"
            required={true}
          />
        </div>

        {/* Veiculo */}
        <div className="text1-black">Veículo</div>

        <div className="containerbloco" class="col-5 flex">
          <div>
            <div className="textInput">Tipo de veículo</div>
            <Dropdown
              data={tipoVeiculo}
              placeholder=""
              msnWarning="Informe o transporte utilizado."
              value={veiculo}
              onchange={(item) => setVeiculo(item.target.value)}
              required={true}
            />
          </div>

          <InputText
            title="Placa"
            placeholder="Digite a Placa do veículo"
            value={placa}
            onchange={(item) => setPlaca(item.target.value)}
            msnWarning="Obrigatório preencher a placa do veículo"
            required={true}
          />
        </div>
      </section>

      {/* ACOMPANHANTE */}
      <CadastroAcomTrans />

      <Rodape valorBar={100} voltar={() => handleVoltar(-1)} />
    
    
    
    </form>
  );
};

export default CadastroVisitante;
