// import "../../styles/cadastro.css";
import { useNavigate, useParams } from "react-router-dom";
import Rodape from "../../components/Rodape";
import BtnTipoCadastro from "../../components/BtnTipoCadastro";

import { useState } from "react";
import { useEffect } from "react";

import LogoColor from "../../assets/image/logo_color.svg";



const Cadastro = () => {

    const { id, cidade } = useParams();

    const navigation = useNavigate();

    const [novoCadastro, setNovoCadastro] = useState("");
    const [estouDePassagem, setEstouDePassagem] = useState("");
    const [atualizar, setAtualizar] = useState("");
    const [cadastroEmOutroMunicipio, setCadastroEmOutroMunicipio] = useState("");

    // console.log("Id da cidade passando: ", id );

    const cadastro = {
        novo_cadastro: novoCadastro,
        estou_de_passagem: estouDePassagem,
        atualizar: atualizar,
        cadastro_em_outro_municipio: cadastroEmOutroMunicipio,
    };

    const passagem = {

        nao_hospedado: true,
        hospedado_outro_municipio: false,


    };

    localStorage.setItem("cidade", cidade)

    localStorage.setItem("@passagem:Etapa", JSON.stringify({ passagem }));
    if (id) {
        localStorage.setItem("id_municipio", id);
    }
    else {
        localStorage.setItem("id_municipio", 1);
    }


    const handleAvanca = () => {
        localStorage.setItem("@cadastro:Etapa0", JSON.stringify({ cadastro }));

        if (id) {
            localStorage.setItem("id_municipio", id);
        }
        else {
            localStorage.setItem("id_municipio", 1);
        }

        navigation("/novo");
    }


    useEffect(() => {

        const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa0"));
        if (dados != null) {

            setNovoCadastro(dados.cadastro.novoCadastro);
            setEstouDePassagem(dados.cadastro.estouDePassagem);
            setAtualizar(dados.cadastro.atualizar);
            setCadastroEmOutroMunicipio(dados.cadastro.cadastroEmOutroMunicipio);
        }
    }, []);


    const tipoCadastro = (novoCadastro, estou_de_passagem, atualizar, tela) => {
        cadastro.novo_cadastro = novoCadastro;
        cadastro.estou_de_passagem = estou_de_passagem;
        cadastro.atualizar = atualizar;
        cadastro.cadastro_em_outro_municipio = cadastroEmOutroMunicipio;

        localStorage.setItem("@passagem:Etapa0", JSON.stringify({ cadastro }));

        navigation(tela)

    }


    return (

        <section className="container" onSubmit={handleAvanca}>

            <section className="home_topo_conteudo" href="/">
                <img src={LogoColor} alt="logo" className="logoColor" href="/" />
                <img src={LogoColor} alt="logo" className="logo" href="/" />
            </section>

            <section className="corpoModelo flex">


                <div className="corpo1 flex">
                    <div className="titulo-G">Olá, seja muito <br />bem vindo a  {cidade}!</div>
                    <label className="subtitulo-r"><br />Somos considerados um dos destino mais seguros para se conhecer!
                        Sendo assim é necessário a <strong>Cadastro Turístico </strong>
                        para aproveitar o máximo sua experiência!
                    </label><br />
                    <div className="subtitulo-m marginBotton">Isso levará alguns segundos!</div>
                </div>


                <div className="corpo2C flex">

                    <BtnTipoCadastro
                        // onclick={() => navigation("/novo")}
                        onclick={() => tipoCadastro(true, false, false, "/novo")}

                        title="Vou ou Estou Hospedado(ar)"

                        value={novoCadastro}
                        onchange={item => setNovoCadastro(item.target.value)}
                        descricao="Utilize está opção caso você pretenda se hospedar no município."

                    />
                    <BtnTipoCadastro
                        // onclick={() => navigation("/Passagem")}
                        onclick={() => tipoCadastro(false, true, false, "/Passagem")}
                        title="Estou de Passagem"

                        value={estouDePassagem}
                        onchange={item => setEstouDePassagem(item.target.value)}


                        descricao="Caso esteja apenas de passagem ou hospedado em outro município."
                    />

                    <BtnTipoCadastro
                        //onclick={() => navigation("/CadastroCEOM")}
                        onclick={() => tipoCadastro(false, false, true, "/CadastroCEOM")}
                        title="Estou cadastrado em outro município"

                        value={cadastroEmOutroMunicipio}
                        onchange={item => setCadastroEmOutroMunicipio(item.target.value)}

                        descricao="Caso tenha feito o cadastro na plataforma sllips no município que você está hospedado. Esta é a opção 
                    certa pra você!"
                    />


                    <BtnTipoCadastro
                        onclick={() => navigation("/CadastroEmpresa")}
                        title="Agência de viagem"

                        value={atualizar}
                        onchange={item => setAtualizar(item.target.value)}

                        descricao="Toda agência de viagem que visitar o município com Ônibus de Excursão deverá fazer o cadastramento!"
                    />


                    {/* <BtnTipoCadastro
                        onclick={() => navigation("/Atualizar")}
                        title="Atualizar"

                        value={atualizar}
                        onchange={item => setAtualizar(item.target.value)}

                        descricao="Caso já tenha feito o cadastro na sllips e tenha expirado o QRcode. Utilize esta opção para atualizar seus dados novamente!"
                    /> */}

                </div>


            </section>

            <Rodape
                valorBar={16.6}
                voltar={() => navigation("/")}
            />

        </section>
    )
}
export default Cadastro;