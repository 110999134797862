import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
// import Logo from "../../assets/image/logo_color.svg";
import Dropdown from "../../components/Dropdown";
import Rodape from "../../components/Rodape";
// import "../../styles/cadastro-novo.css";

import LogoColor from "../../assets/image/logo_color.svg";
import InputText from "../../components/InputText";
import { BuscaMunicipios } from "../../models/buscaMunicipios";
import { BuscaEstados } from "../../models/buscaEstados";
import DropdownEstadoUF from "../../components/DropdownEstadoUF";

const CadastroNovo = () => {
  const navigation = useNavigate();

  const [chegada, setChegada] = useState("");
  const [partida, setPartida] = useState("");
  const [viajandoCom, setViajandoCom] = useState("");
  const [tipoturismo, setTipoTurismo] = useState("");
  const [conhecemunicipio, setConheceMunicipio] = useState("");
  const [cidadeHospedado, setCidadeHospedada] = useState("");
  const [tiporoteiro, setTipoRoteiro] = useState("");

  const [cidade, setCidade] = useState("");
  const [dataCidade, setDataCidade] = useState("");

  const [estado, setEstado] = useState("");
  const [dataEstado, setDataEstado] = useState([]);

  const { id } = useParams();

  const cadastro = {
    id_municipio: id,
    tipo_turismo: tipoturismo,
    viajando_com: viajandoCom,
    data_chegada: chegada,
    data_partida: partida,
    conhece_municipio: conhecemunicipio,
    tipo_roteiro: tiporoteiro,
    uf_hospedado: estado,
    cidade_hospedada: cidade,
  };

  const options = [
    { key: 1, value: "Turismo Comercial" },
    { key: 2, value: "Turismo Educativo" },
    { key: 3, value: "Turismo de Excursão" },
    { key: 4, value: "Turismo Evento" },
    { key: 5, value: "Turismo de Lazer" },
    { key: 6, value: "Turismo Negócios" },
    { key: 7, value: "Turismo Religioso" },
    { key: 8, value: "Turismo Saúde" },
    { key: 9, value: "Visita Familiar" },
  ];

  const options2 = [
    { key: 1, value: "Família" },
    { key: 2, value: "Amigos" },
    { key: 3, value: "Sozinho" },
  ];

  const options3 = [
    { key: 1, value: "Estou hospedado em outro município" },
    { key: 2, value: "Estou só de passagem" },
    { key: 3, value: "Vou/Estou hospedado no município" },
  ];

  const handleOptionChange = (e) => {
    setConheceMunicipio(e.target.value);
  };

  const handleAvanca = () => {
    localStorage.setItem("@cadastro:Etapa1", JSON.stringify(cadastro));
    navigation("/visitante/");
  };

  console.log("cadastro", cadastro);

  useEffect(() => {
    const fetchdata = async () => {
      const res = await BuscaEstados();
      if (res.length > 0) {
        setDataEstado(res);
      }
    };
    fetchdata();
  }, []);

  useEffect(() => {
    const fetchdata = async () => {
      const res = await BuscaMunicipios(estado);
      if (res.length > 0) {
        setDataCidade(res);
      }
    };
    if (estado && estado.length > 0) {
      fetchdata();
    }
  }, [estado]);

  useEffect(() => {
    const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));
    localStorage.setItem("id_municipio",id);
    if (dados != null) {
      setChegada(dados.data_chegada);
      setPartida(dados.data_partida);
      setViajandoCom(dados.viajando_com);
      setTipoTurismo(dados.tipo_turismo);
      setConheceMunicipio(dados.conhece_municipio);
      setTipoRoteiro(dados.tipo_roteiro);
    }
  }, []);

  return (
    <form className="container" onSubmit={handleAvanca}>
      <section className="home_topo_conteudo">
        <img src={LogoColor} alt="logo" className="logoColor" />
        <img src={LogoColor} alt="logo" className="logo" />
      </section>

      <section className="corponovo flex">
        <div className="ContainerTitulo marginBotton">
          <div className="titulo-G">Me conte mais sobre a viagem!</div>

        </div>

        <div className="containerbloco">
          <div>
            <div className="TitleCampo">Tipo de roteiro</div>
            <Dropdown
              data={options3}
              //placeholder="Selecione o motivo"
              onchange={(item) => setTipoRoteiro(item.target.value)}
              value={tiporoteiro}
              msnWarning="Selecione o roteiro."
              required={true}
            />
          </div>
        </div>

        {(tiporoteiro === "Estou hospedado em outro município" ||
          tiporoteiro === "Vou/Estou hospedado no município") && (
          <div className="containerbloco">
            <div className="TitleCampo">
              Em qual cidade voçê está hospedado?
            </div>

            <div className="containerbloco" class="col-5 flex">
              <div>
                <div className="textInput">Estado</div>

                <DropdownEstadoUF
                  data={dataEstado}
                  placeholder=""
                  msnWarning="Selecione o Estado."
                  value={estado}
                  onchange={(item) => setEstado(item.target.value)}
                  required={true}
                />
              </div>

              {estado ? (
                <div style={{ width: "100%" }}>
                  <div className="textInput">Cidade</div>
                  <DropdownEstadoUF
                    data={dataCidade}
                    placeholder=""
                    msnWarning="Selecione a cidade"
                    value={cidade}
                    onchange={(item) => setCidade(item.target.value)}
                    required={true}
                  />
                  
                </div>

              ) :
              <div>
                <InputText
                  title="Cidade"
                  placeholder="Digite o nome da cidade"
                  value={cidade}
                  onchange={(item) => setCidade(item.target.value)}
                  msnWarning="Obrigatório preencher a cidade"
                  required={true}
                  disabled={true}
                />
              </div>
              }


            </div>


          </div>
        )}

        <div className="col-5 flex">
          <div>
            <div className="TitleCampo">Tipo de viagem</div>
            <Dropdown
              data={options}
              //placeholder="Selecione o motivo"
              onchange={(item) => setTipoTurismo(item.target.value)}
              value={tipoturismo}
              msnWarning="Selecione o tipo de turismo realizado."
              required={true}
            />
          </div>

          <div>
            <div className="TitleCampo">Você está viajando</div>
            <Dropdown
              data={options2}
              //placeholder="Selecione"
              onchange={(item) => setViajandoCom(item.target.value)}
              value={viajandoCom}
              msnWarning="Selecione se possui mais alguém com você."
              required={true}
            />
          </div>
        </div>

        <div className="containerbloco">
          <div className="TitleCampo">Qual o seu período de permanência?</div>
          <div className="col-5 flex">
            <fieldset className="borda-legenda">
              <legend className="legenda">Chegada</legend>
              <input
                required
                className="data"
                type="date"
                value={chegada}
                onChange={(item) => setChegada(item.target.value)}
              />
            </fieldset>

            <fieldset className="borda-legenda">
              <legend className="legenda">Partida</legend>
              <input
                required
                className="data"
                type="date"
                value={partida}
                onChange={(item) => setPartida(item.target.value)}
              />
            </fieldset>
          </div>
        </div>

        <div className="containerbloco">
          <div className="TitleCampo">Você já vistou nosso município</div>
          <div className="col-2 flex">
            <div>
              <input
                type="radio"
                value="false"
                name="visitei"
                checked={conhecemunicipio === "false"}
                onChange={handleOptionChange}
              />
              <label className="textvisita">Não! É a primeira visita!</label>
            </div>
            <div>
              <input
                type="radio"
                value="true"
                name="visitei"
                checked={conhecemunicipio === "true"}
                onChange={handleOptionChange}
              />
              <label className="textvisita">
                Sim! Já visitei outras vezes!
              </label>
            </div>
          </div>
        </div>
      </section>
      <Rodape valorBar={50} voltar={() => navigation(-1)} avancar={true} />
    </form>
  );
};
export default CadastroNovo;
