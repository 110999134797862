import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";

import Cadastro from "../pages/Cadastro";
import EstouPassagem from "../pages/EstouPassagem";

import CadastroNovo from "../pages/CadastroNovo";

import CadastroVisitante from "../pages/CadastroVisitante";

import CadastroAcompanhante from "../pages/CadastroAcompanhante";

import CadastroContato from "../pages/Contato";

import CadastroAcomTrans from "../pages/CadastroAcomTrans";

import CadastroConcluido from "../pages/CadastroConcluido";

import CadastroCEOM from "../pages/CadastroCEOM";
import Atualizar from "../pages/Atualizar";

import CadastroTurísticoMunicipal from "../pages/CadastroTurísticoMunicipal";

import PoliticaDePrivacidade from "../pages/Politica de Privacidade";

import InformaçõesDaEmpresa from "../pages/Informações da Empresa";

import CadastroEmpresa from "../pages/CadastroEmpresa";

import Onibus from "../pages/Onibus";

import NossaHistoria from "../pages/NossaHistoria";

import Privacidade from "../pages/Privacidade";

import CadastroGuiaAgencia from "../pages/CadastroGuiaAgencia";

import Perfil from "../pages/Perfil";

import Pagamento from "../pages/Pagamento";

import TermosdeUso  from "../pages/TermosdeUso"

import Destino from "../pages/Destino";

const AppRoutes = () => {

    return (
        <Routes>
            <Route exact path="/" element={<Home/>}/>

            <Route exact path="destino/" element={<Destino/>}/>

            <Route exact path="cadastro/:id/:cidade" element={<Cadastro/>}/>

            <Route exact path="passagem" element={<EstouPassagem/>}/>                
           
            <Route exact path="novo/:id/:cidade" element={<CadastroNovo/>}/>
          
            <Route exact path="visitante/" element={<CadastroVisitante/>}/>
         
            <Route exact path="acompanhante" element={<CadastroAcompanhante/>}/>
           
            <Route exact path="contato" element={<CadastroContato/>}/>
          
            <Route exact path="acomtrans" element={<CadastroAcomTrans/>}/>

            <Route exact path="concluido/:id/:chegada/:partida" element={<CadastroConcluido/>}/>
          
            <Route exact path="CadastroCEOM" element={<CadastroCEOM/>}/>

            <Route exact path="Atualizar" element={<Atualizar/>}/>

            <Route exact path="Perfil" element={<Perfil/>}/>
          
            <Route exact path="CadastroTurísticoMunicipal" element={<CadastroTurísticoMunicipal/>}/>

            <Route exact path="PoliticaDePrivacidade" element={<PoliticaDePrivacidade/>}/>

            <Route exact path="InformaçõesDaEmpresa" element={<InformaçõesDaEmpresa/>}/>

            <Route exact path="CadastroEmpresa" element={<CadastroEmpresa/>}/>

            <Route exact path="Onibus" element={<Onibus/>}/>

            <Route exact path="NossaHistoria" element={<NossaHistoria/>}/>

            <Route exact path="Privacidade" element={<Privacidade/>}/>

            <Route exact path="Termosdeuso" element={<TermosdeUso/>}/>

            <Route exact path="GuiaAgencia" element={<CadastroGuiaAgencia/>}/>

            {/*  NÂO VAI UTILIZAR MAIS NO MOMENTO
                <Route exact path="Payment" element={<Pagamento/>} /> 
            */}


        </Routes>
        )
}
export default AppRoutes;