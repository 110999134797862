

import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo_color.svg";
import Dropdown from "../../components/Dropdown";
import DropdownCity from "../../components/DropdownCity";
import DropdownUf from "../../components/DropdownUf";
import DropdownPaises from "../../components/DropdownPaises";
import Rodape from "../../components/Rodape";
import { useState, useEffect, useRef } from "react";
import InputText from "../../components/InputText";
import IMask from "imask";
import { MuiTelInput } from 'mui-tel-input'

import LogoColor from "../../assets/image/logo_color.svg";

import axios from 'axios';

import { paises } from "../../utils/paises";


const CadastroContato = () => {


  const navigation = useNavigate();

  const [cidade, setCidade] = useState("");
  const [estadouf, setEstadoUF] = useState('');
  const [pais, setPais] = useState('');

  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [contato, setContato] = useState("");
  const [parentesco, setParentesco] = useState("");
  const [emergenciaTel, setEmergenciaTel] = useState("");

  const [selectedUf, setSelectedUf] = useState("0");
  const [dataCidade, setDataCidade] = useState("");
  const [dataUf, setDataUf] = useState("");

  const opcoes = [
    { key: 1, value: "Avô(ó)" },
    { key: 2, value: "Esposo(a)" },
    { key: 3, value: "Filho(a)" },
    { key: 4, value: "Irmão(ã)" },
    { key: 5, value: "Mãe" },
    { key: 6, value: "Pai" },
    { key: 7, value: "Tio(a)" }
  ];



  const handleAvanca = () => {
    localStorage.setItem("@cadastro:Etapa4", JSON.stringify({
      cidade,
      email,
      telefone,
      nome: contato,
      parentesco,
      emergenciaTel,
      pais,
      estadouf,

    }));
    navigation("/acomtrans")
  }


  useEffect(() => {


    const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa4"));

    if (dados != null) {

      setCidade(dados.cidade);
      setEmail(dados.email);
      setTelefone(dados.telefone);
      setContato(dados.nome);
      setParentesco(dados.parentesco);
      setEmergenciaTel(dados.emergenciaTel)

      setEstadoUF(dados.estadouf);
      setPais(dados.pais);


    }
  }, []);

  const TelefoneMask = (value, info) => {

    if (value.length <= 17) {
      setTelefone(value);
    }
  }

  const TelefoneMaskEmergencial = (value, info) => {
    if (value.length <= 17) {
      setEmergenciaTel(value);
    }
  }


  //////////////////////// Rotina buscar cidade e estado //////////////////////////

  useEffect(() => {
    if (selectedUf === "0") {
      return;
    }
    try {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`
        )
        .then((response) => {
          setDataCidade(response.data);

        });

    }
    catch (err) {
      console.log("Erro ao buscar dados da api: ", err);
    }

  }, [selectedUf]);

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/")
      .then((response) => {
        setDataUf(response.data);
      });
  }, [pais]);

  function handleSelectUf(value) {
    setSelectedUf(value);
    setEstadoUF(value);
  }

  function handleSelectCity(value) {
    setCidade(value);
  }



  ////////////////////////////////////////////////////////////////////////////////////////////////

  // const maskOptions = {
  //   mask: " (000) 00000 0000"
  // }
  // const maskRef = useRef(null);

  // useEffect(() => {


  //   maskRef.current = IMask(document.getElementById("emergenciaTel"), maskOptions);
  //   maskRef.current = IMask(document.getElementById("telefone"), maskOptions);

  // }, [emergenciaTel, telefone]);

  return (
    <form className="container" onSubmit={handleAvanca}>

      <section className="home_topo_conteudo">
        <img src={LogoColor} alt="logo" className="logoColor" />
        <img src={LogoColor} alt="logo" className="logo" />
      </section>



      <div className="corpo flex">

      
        <div className="text1-black">Contato</div>
        <div className="text2">
          É importante preencher essas informações com precisão. <br />
          <strong>
            São usadas para entrar em contato com você ou para fins de identificação e comunicação!
          </strong>
        </div>


        {/* <InputText
          title="Qual País você mora?"
          placeholder="País"
          value={pais}
          onchange={(item) => setPais(item.target.value)}
          msnWarning="Informe o País que você mora."
          required={true}
        /> */}


        <div className="textInput"> Qual País você mora? </div>
        <DropdownPaises
          data={paises}
          placeholder="Selecionar o País"
          onchange={(event) => setPais(event.target.value)}
          value={pais}
          msnWarning="Informe o País que você mora."
          required={true}
        />

        {pais === "Brasil" &&

          <>
            <div className="textInput"> Qual Estado você mora? </div>
            <DropdownUf
              data={dataUf}
              placeholder="Selecionar Estado"
              onchange={(event) => handleSelectUf(event.target.value)}
              value={estadouf}
              msnWarning="Informe o Estado que você mora."
              required={true}
            />

            {estadouf &&
              <>
                <div className="textInput"> Qual Cidade você mora? </div>
                <DropdownCity
                  data={dataCidade}
                  placeholder="Selecionar Cidade"
                  onchange={(event) => handleSelectCity(event.target.value)}
                  value={cidade}
                  msnWarning="Informe o Cidade que você mora."
                  required={true}
                />
              </>
            }
          </>
        }

        {/* <InputText
          type="email"
          title="Email"
          placeholder="Digite seu Email"
          value={email}
          onchange={(item) => setEmail(item.target.value)}
          msnWarning="Informe seu email."
          required={true}
        /> */}

        {/* <InputText
          id="telefone"
          title="Telefone de contato"
          placeholder="+{00} (000) 00000 0000"
          value={telefone}
          onchange={(item) => setTelefone(item.target.value)}
          msnWarning="Informe seu telefone."
          required={true}
        /> */}


        <div className="textInput"> Telefone de contato </div>

        <MuiTelInput
          id="telefone"
          variant="outlined"
          placeholder="(00) 00000 0000"
          defaultCountry="BR"
          value={telefone}
          onChange={TelefoneMask}
          flagSize="medium"
          forceCallingCode


          inputProps={
            {
              style: { fontSize: 15, border: 10 }
            }
          }

        />


        <div className="text1-black marginTop">Contato de emergência</div>
        <div className="text2">
          É importante ter um número emergencial cadastrado.
        </div>

        <InputText
          title="Nome"
          placeholder="Nome do contato"
          value={contato}
          onchange={(item) => setContato(item.target.value)}
          msnWarning="Informe o nome do contato."
          required={true}
        />

        <div className="textInput">Parentesco</div>
        <Dropdown
          data={opcoes}
          placeholder=""
          onchange={(item) => setParentesco(item.target.value)}
          value={parentesco}
          msnWarning="Informe seu parentesco com o contato."
          required={true}
        />

        {/* <InputText
          id="emergenciaTel"
          title="Telefone de contato"
          placeholder="+{00} (000) 00000 0000"
          value={emergenciaTel}
          onchange={(item) => setEmergenciaTel(item.target.value)}
          msnWarning="Informe o telefone do contato."
          required={true}
        /> */}

        <div className="textInput"> Telefone de contato </div>

        <MuiTelInput
          id="emergenciaTel"
          variant="outlined"
          placeholder="(00) 00000 0000"
          defaultCountry="BR"
          value={emergenciaTel}
          onChange={TelefoneMaskEmergencial}
          flagSize="medium"
          forceCallingCode

          inputProps={
            {
              style: { fontSize: 15, border: 10 }
            }
          }

        />
      </div>

      <Rodape
        voltar={() => navigation("/acompanhante")}
        avancar={true}
        valorBar={83}
      />
    </form>
  );
}
export default CadastroContato;