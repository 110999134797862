import "../components/DropdownCidade.css";
import Warning from "../assets/image/warning.png";
const DropdownEstadoUF = ({ data, placeholder, onchange, value, msnWarning, required}) => {
    return (
        <>
            {data &&
                <>
                    <select required={required} className="dropdown" onChange={onchange} value={value}>
                    <option value="" disabled selected>{placeholder}</option>

                    {data.map((item) => {
                        return <option className="option" key={item.ID} value={item.ID}>{item.Nome}</option>
                    })}

                    </select>
                    <div className="warning">
                        <img src={Warning} alt="ícone de alerta" />
                        {msnWarning}
                    </div>
                </>
            }
            
        </>
    )
}

export default DropdownEstadoUF;
