import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import GooglePlay from "../../assets/image/googlePlay.svg";
import Appstore from "../../assets/image/appStore.svg";

import Facebook from "../../assets/image/facebook.svg";
import Instagram from "../../assets/image/instagram.svg";
import Twiter from "../../assets/image/twiter.svg";
import { Link } from "react-router-dom";


import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";


const TermosdeUso = () => {


    const carousel = useRef();
    const [width, setwidth] = useState(0);

    useEffect(() => {
        setwidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
    }, [])



    return (

        <section className="container">

            <section className="home_corpo2">
                <div className="pesquisa">

                    <img src={LogoColor} alt="Logo" />

                    <div>
                        <div className="PL">Termos de Uso</div>
                        <div className="ID-">Termos de Uso da Tecnologia Sllips</div>
                    </div>
                    <div className="ID2">


                        <p /><strong>1. DAS DEFINIÇÕES</strong>

                        <ul>

                            <p /><li><strong>1.1</strong> Para efeitos do presente instrumento, todas as palavras e expressões
                                constantes da lista abaixo, deverão ser entendidas conforme o respectivo significado: </li>

                            <p /><li><strong>1.1.1. Sllips:
                            </strong> é a empresa desenvolvedora do software Cliente;</li>

                            <p /><li><strong>1.1.2. Software:
                            </strong> é a ferramenta contratada pelo Cliente, na forma do contrato de
                                prestação de serviços profissionais;</li>

                            <p /><li><strong>1.1.3. Cliente:
                            </strong> é a pessoa que utiliza do software em nome do cliente como também
                                prepostos/funcionários/terceirizados que utilizam o software em seu nome</li>


                            <p /><li><strong>1.1.4. Usuário:
                            </strong> é a pessoa física ou jurídica cujo licenciamento ao uso do software foi
                                concedido pela Sllips.</li>

                            <p /><li><strong>1.1.5. Controlador:
                            </strong> é a pessoa natural ou jurídica, de direito público ou privado, a
                                quem competem as decisões referente ao tratamento de dados pessoais.</li>

                            <p /><li><strong>1.1.6. Operador:
                            </strong> é a pessoa natural ou jurídica, de direito público ou privado, que
                                realiza o tratamento de dados pessoais em nome do controlador.</li>

                            <p /><li><strong>1.1.7. Autoridade Nacional de Proteção de Dados (ANPD):
                            </strong> órgão da administração
                                pública responsável por zelar, implementar e fiscalizar o cumprimento da Lei nº
                                13.709/2018 em todo o território naciona.</li>

                        </ul>


                        <p /><strong>2. DA ACEITAÇÃO DOS TERMOS E CONDIÇÕES</strong>

                        <ul>

                            <p /><li><strong>2.1.</strong> Estes termos de uso ("Termos") regem o uso do software fornecido pela Sllips.</li>

                            <p /><li><strong>2.2.</strong>
                                O objetivo do presente Termo de Uso é manter os padrões de integridade e
                                excelência para os Usuários que acessarem os serviços disponibilizados no software,
                                bem como estabelecer a política e regulamento para tal acesso, razão pela qual a sua
                                leitura é obrigatória.</li>

                            <p /><li><strong>2.3.</strong>
                                O cadastro, acesso e/ou uso dos serviços disponibilizados no software pelo
                                Usuários implicam na aceitação plena e sem reservas de todos os termos e condições
                                deste Termo de Uso, seja no momento da navegação/acesso e/ou no momento do
                                efetivo cadastro, visto que os termos e condições estão sujeitos a alterações. Caso o
                                Usuário não aceite qualquer termo ou condição, deverá cessar imediatamente o uso do
                                software, sob qualquer forma ou em qualquer hipótese. </li>

                            <p /><li><strong>2.4.</strong>
                                O uso dos Serviços também está sujeito à Política de Privacidade bem como ao
                                contrato particular estabelecido entre as partes. </li>

                            <p /><li><strong>2.5.</strong>
                                A Sllips poderá – a critério exclusivo - alterar os termos e condições deste Termo
                                de Uso, a qualquer momento, mediante simples comunicação das mudanças aos
                                Usuários, da seguinte maneira: (i) publicação da nova versão dos Termos e Condições
                                no Site; ou (ii) envio da atualização para ciência, quando do primeiro acesso ao software
                                pelo Usuário após a efetiva atualização.
                            </li>

                            <p /><li><strong>2.6.</strong>
                                O software destina-se ao uso comercial e profissional, não sendo admitido seu
                                uso para fins ilícitos.
                            </li>

                            <p /><li><strong>2.7.</strong>
                                O presente Termos de Uso é um documento complementar ao contrato de
                                prestação de serviços profissionais celebrado entre Sllips e Licenciada, não o revogando
                                ou substituindo-o.

                            </li>


                        </ul>

                        <p /><strong>3. DO USO DOS SERVIÇOS</strong>

                        <ul>

                            <p /><li><strong>3.1.</strong> O Usuário compromete-se a utilizar o software sempre respeitando e
                                observando o presente Termo de Uso, a legislação aplicável, bem como à moral e aos
                                bons costumes. </li>


                        </ul>

                        <p /><strong>4. DAS OBRIGAÇÕES E DEVERES DO CLIENTE E DO USUÁRIO</strong>

                        <ul>

                            <p /><li><strong>4.1.</strong> O Cliente e o Usuário concordam em navegar / utilizar o software em estrita
                                observância à legislação aplicável, a este Termo de Uso e Condições e à Política de
                                Privacidade, bem como à moral e aos bons costumes.  </li>

                            <p /><li><strong>4.2.</strong> Responsabilidade pelo Uso: Você é o único responsável pelo uso que faz do
                                software. Você concorda em utilizar o software de acordo com estes Termos,
                                respeitando os direitos de propriedade intelectual da Empresa e de terceiros.  </li>

                            <p /><li><strong>4.3. Ao utilizar o software, você concorda em não:</strong></li>
                            <ul>
                                <p /><li><strong>(a)</strong>realizar qualquer atividade que viole a lei, regulamentos ou direitos de terceiros;</li>
                                <p /><li><strong>(b)</strong>tentar acessar áreas não autorizadas do software;</li>
                                <p /><li><strong>(c)</strong>interferir no funcionamento adequado do software ou sobrecarregar sua
                                    infraestrutura;</li>
                                <p /><li><strong>(d)</strong>compartilhar ou distribuir conteúdo ilegal, difamatório, obsceno, ofensivo ou
                                    prejudicial;
                                </li>
                                <p /><li><strong>(e)</strong> prejudicar a reputação ou a imagem da Sllips por meio de ações ou declarações falsas
                                    ou difamatórias;
                                </li>
                                <p /><li><strong>(f)</strong> utilizar o software para qualquer propósito fraudulento ou ilegal;</li>
                                <p /><li><strong>(g)</strong>Violar ou alterar de qualquer forma os sistemas de autenticação, verificação de
                                    identidade e/ou de segurança dos Serviços/Produtos ou Cadastros de Usuário e/ou
                                    administradores e/ou responsáveis pelos Serviços, incluindo, sem limitação, tentativa
                                    de acesso a dados não destinados ao Usuário, tentativa de alteração ou falsificação de
                                    informações ou até acesso de Serviços ou contas sem a autorização expressa para fazê-lo;</li>
                                <p /><li><strong>(h)</strong>Utilizar qualquer programa, comando ou grupo de comandos ou enviar mensagens
                                    de qualquer natureza, destinadas a interferir a sessão estabelecida por um Usuário em
                                    qualquer lugar na Internet, ou alterar de alguma forma as comunicações de Internet e
                                    de informações do software; efetuar qualquer tipo de monitoramento que envolva a
                                    interceptação de informações que não se destinem ao Usuário; enviar ou transmitir
                                    arquivos que contenham vírus ou outras características destrutivas que possam afetar
                                    adversamente a operação de um computador e/ou possam afetar o bom
                                    funcionamento dos Serviços; utilizar qualquer programa de computador ou qualquer
                                    outra forma que induza a engano, com a finalidade de auferir vantagens patrimoniais
                                    ou comerciais em favor do Usuário, além de efetuar qualquer ataques cibernéticos à
                                    rede de comunicação e sistema da Sllips;</li>
                                <p /><li><strong>(i)</strong>modificar, reproduzir, comercializar ou ceder a terceiro (exceto ao Usuário), por
                                    qualquer forma, mesmo gratuita, ou fazer engenharia reversa, edição, compilação,
                                    descomplicação, recompilação ou criar derivações do software ou de chaves de uso, no
                                    todo ou em parte;</li>
                                <p /><li><strong>(j)</strong>acessar o software ou o código-fonte de modo não autorizado; </li>
                                <p /><li><strong>(k)</strong> inserir no software qualquer código malicioso ou executável não autorizado, e; </li>
                                <p /><li><strong>(l)</strong>utilizar o software para fins ilícitos ou ilegais do ponto de vista de legislações nacionais
                                    ou internacionais aplicáveis.
                                </li>
                            </ul>

                            <p /><li><strong>4.4.</strong> O Usuário será responsável - civil e criminalmente - pelos danos morais/materiais
                                ou por qualquer prejuízo que venha a causar à Sllips, em decorrência do não
                                cumprimento ou do cumprimento irregular das disposições previstas neste Termo de
                                Uso, bem como por quaisquer danos que venha a causar a terceiros e/ou a outros
                                Usuários, por ato ou fato resultante da utilização em desacordo com as disposições
                                prevista neste Termo de Uso ou em quaisquer outros avisos, regulamentos de uso,
                                instruções, políticas e/ou regulamentos editados e devidamente publicados pela Sllips
                                em seu Site e/ou disponibilizado dentro do próprio software.</li>

                            <p /><li><strong>4.5.</strong>. No caso de danos ocasionados por Usuários à Sllips, em razão da utilização do
                                software em desconformidade com o presente Termos de Uso, o Cliente responsável
                                pelo Usuário, responderá de forma solidária com este pelos danos ocasionados. </li>


                        </ul>


                        <p /><strong>5. DA LIMITAÇÃO DA RESPONSABILIDADE PELOS SERVIÇOS PRESTADOS</strong>

                        <ul>

                            <p /><li><strong>5.1.</strong> A Sllips contrata o acesso à Internet e servidores de terceiros para o
                                desenvolvimento e prestação de seus serviços, razão pela qual o Usuário e o Cliente
                                reconhece que os Serviços poderão, eventualmente, estar indisponíveis em decorrência
                                de dificuldades técnicas, falhas na Internet ou no provedor, bem como por qualquer
                                outro motivo alheio à vontade da Sllips, incluindo, mas não se limitando, a eventos de
                                caso fortuito ou de força maior. Desse modo, a Sllips não garante a disponibilidade e
                                continuidade do funcionamento dos Serviços, do mesmo modo que não garante a
                                utilização dos Serviços para realizar qualquer atividade em particular, tampouco seu
                                perfeito funcionamento, principalmente, mas sem limitação, naquilo concernente à
                                efetiva utilização dos Serviços pelo Usuário, na totalidade ou apenas a parte do
                                software. A Sllips está isenta da responsabilidade por danos e prejuízos de qualquer
                                natureza que sejam decorrentes, de forma direta, indireta ou remota, da interrupção,
                                suspensão, cessação, falta de disponibilidade ou da descontinuação do funcionamento
                                dos Serviços, bem como aqueles oriundos do uso fraudulento de utilidade, receita ou
                                benefício que os Usuários poderiam atribuir à Sllips, à confiabilidade dos Serviços, e, em
                                particular, às falhas de acesso aos Serviços.
                            </li>

                            <p /><li><strong>5.2.</strong> Em conformidade com a legislação aplicável, a Sllips não se responsabiliza em
                                hipótese alguma por qualquer dano, inclusive danos cessantes ou morais, perdas de
                                economia previstas ou perda de contratos e/ou negócios, prejuízos ou perdas nos
                                equipamentos do Cliente, do Usuário, dos prestadores de serviços por ele autorizado
                                e/ou de terceiros alheias à Sllips, inclusive falhas do sistema, do servidor ou da Internet.
                            </li>

                            <p /><li><strong>5.3.</strong>Não há vínculo de emprego entre a Sllips e Cliente tampouco entre Sllips e Usuário.

                            </li>


                        </ul>


                        <p /><strong>6. DA PROPRIEDADE INTELECTUAL E DOS DIREITOS RESERVADOS </strong>

                        <ul>

                            <p /><li><strong>6.1.</strong> Todos os direitos de propriedade intelectual relativos ao software pertencem
                                exclusivamente à Sllips, incluindo, sem limitação, os direitos autorais, marcas, insígnias,
                                trade dress, layout, símbolos, outros sinais distintivos, segredos de negócio, nomes de
                                domínio, software, manuais, fotos, imagens, descrições, textos e todo e qualquer direito
                                incidente sobre o desenvolvimento e/ou uso do Site e do software, quer estejam tais
                                direitos registrados ou não, e o Cliente e Usuário concordam em se abster de pleitear
                                ou reclamar, a qualquer tempo, tais direitos de propriedade intelectual, como se fossem
                                de sua titularidade.
                            </li>

                            <p /><li><strong>6.2.</strong> A Sllips é titular de direitos sobre todos os bancos de dados, conteúdos, marcas,
                                imagens e softwares utilizados no funcionamento do software, protegidos de acordo
                                com a legislação nacional e internacional aplicável à propriedade intelectual.
                            </li>


                            <p /><li><strong>6.3.</strong> A reprodução, distribuição, modificação, exibição, criação de trabalhos
                                derivados e/ou qualquer outra forma de uso, total ou parcial, pelo Cliente e/ou Usuário
                                e/ou por terceiro por eles autorizados, sem a devida autorização da Sllips, de tais bancos
                                de dados, conteúdos, marcas, imagens e softwares são totalmente vedados sob pena
                                de aplicação das sanções e procedimentos previstos nestes Termos e Condições, no
                                contrato de prestação de serviços entabulado junto ao Cliente e na legislação aplicável.
                            </li>


                        </ul>



                        <p /><strong>7. DA PROTEÇÃO DE DADOS E DA CONFIDENCIALIDADE</strong>

                        <ul>

                            <p /><li><strong>7.1.</strong> As partes declaram que cumprem integralmente com a legislação relativa à
                                proteção de dados, e se comprometem a cumprir integralmente a Lei nº 12.965/2014
                                (Marco Civil da Internet) e Lei nº 13.709/2018 (Lei Geral de Proteção de Dados), sem
                                prejuízo de implementar práticas de segurança da informação previstas nas normas ISO.</li>

                            <p /><li><strong>7.2.</strong> Tanto a Sllips quanto o Cliente, serão considerados controladores de dados
                                pessoais, nos limites de suas atividades comerciais e empresariais.</li>

                            <p /><li><strong>7.3.</strong> A Sllips se responsabiliza pela correta coleta, recepção, compartilhamento e
                                transmissão dos dados pessoais disponibilizada dentro da plataforma. Isto significa que
                                eventual tratamento irregular de dados realizados pelo Cliente com dados pessoais à
                                esta já disponibilizados, não poderá a Sllips ser responsabilizada.</li>

                            <p /><li><strong>7.4.</strong> Em relação aos dados tratados pelo Cliente, este declara estar ciente de que:</li>
                            <ul>
                                <p /><li><strong>(a)</strong>estes dados pessoais tratados deverão possuir base legal específica;</li>
                                <p /><li><strong>(b)</strong>deverá disponibilizar formas de atender aos direitos dos titulares previsto na
                                    legislação; </li>
                                <p /><li><strong>(c)</strong>deverá possuir um plano de contenção em casos de incidentes de segurança,
                                    possuindo como ônus o dever de informar os titulares atingidos e a ANPD, em caso de
                                    incidentes que possam ocasionar riscos as liberdades individuais dos titulares;
                                </li>
                                <p /><li><strong>(d)</strong> respeitar e atender, em todas as atividades de tratamento de dados pessoais, as
                                    regras impostas pela ANPD; </li>
                                <p /><li><strong>(e)</strong>irá orientar os Usuários subordinados a respeitarem as normas expedidas pelo Cliente
                                    para proteção de dados.</li>

                            </ul>

                            <p /><li><strong>7.5.</strong> Tanto Cliente quanto Usuário declaram ciência de que a Sllips poderá se valer da
                                transferência internacional de dados para manutenção da continuidade dos serviços
                                prestados, estando a Sllips responsável pela observância das regras legais estipuladas
                                pela ANPD acerca deste tipo de transferência.</li>


                        </ul>



                        <p /><strong>8. DAS VIOLAÇÕES AO TERMOS DE USO</strong>

                        <ul>

                            <p /><li><strong>8.1.</strong> Qualquer violação destes termos de uso pelo pelo Usuário, autoriza a Sllips a
                                rescindir o contrato firmado entre as partes, independentemente de aviso prévio, desde
                                que notifique o Cliente com pelo menos 15 (quinze) dias de antecedência, sem prejuízo
                                da adoção de medidas cíveis e/ou criminais eventualmente necessárias.</li>


                        </ul>



                        <p /><strong>9. DA LEGISLAÇÃO APLICÁVEL E JURISDIÇÃO</strong>

                        <ul>

                            <p /><li><strong>9.1.</strong> Qualquer controversa existente em relação ao contrato, deverão as partes tentar
                                buscar uma tentativa amigável de conciliação, e, caso esta não dê certo, poderão buscar
                                as vias judiciais posteriormente. </li>

                            <p /><li><strong>9.2.</strong> Estes Termos e Condições, assim como o uso do software, serão regidos pelas leis
                                em vigor na República Federativa do Brasil, sendo estabelecido – desde já - o foro da
                                Cidade de Piumhi/MG, como o competente para dirimir quaisquer controvérsias ou
                                divergências decorrentes destes Termos e Condições e do uso do software e Serviços.
                            </li>

                        </ul>


                    </div>

                    <div className="linha">
                    </div>

                    <div className="ID2">
                    <p /><strong>Última atualização e versões anteriores</strong>
                    <p />Última atualização: 23 de junho de 2024.
                    </div>
                    



                </div>

            </section>



            <section className="home_rodape">
                <div className="box1 flex">


                    <div className="bloco1">

                        <div className="text1">Visitante</div>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Cadastro Turístico Municipal</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Fazer Cadastro CTM</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Ajuda e suporte</div>

                        <Link className="box" to="/InformaçõesDaEmpresa">
                            <div className="text2">Central de ajuda</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Perguntas Frequentes</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Empresa</div>

                        <Link className="box" to="/NossaHistoria">
                            <div className="text2">Nossa história</div>
                        </Link>

                    </div>
                </div>

                <div className="box2 flex">
                    <div>
                        <div className="baixe">Baixe agora</div>
                        <img src={GooglePlay} alt="Google Play" />
                    </div>
                    <div>
                        <div className="disponivel">O app esta disponível <br />apenas para atividade <br />comerciais</div>
                        <img src={Appstore} alt="App Store" />
                    </div>
                </div>
            </section>
            <section className="home_rodape">

                <div className="text">© 2022 Sllips LTDA. All rights reserved</div>

                <Link className="a" to="/Privacidade">
                    <div className="text">.Política de Privacidade</div>
                </Link>

                <Link className="a" to="/InformaçõesDaEmpresa">
                    <div className="text">.Informações da empresa</div>
                </Link>

                <div className="social">

                    <Link className="a" to="https://www.instagram.com/sllips.oficial/">
                        <img src={Instagram} alt="Instagram" />
                    </Link>
                    <img src={Facebook} alt="facebook" />
                    <img src={Twiter} alt="Twiter" />

                </div>
            </section>
        </section>
    )
}

export default TermosdeUso;