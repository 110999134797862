import { FiLogIn, FiLogOut, FiMenu, FiX } from "react-icons/fi"
import InputText from "./InputText"
import API from "../service/api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../service/JwtContext";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { autentication } from "../utils/config";
import LogoColor from "../assets/image/logo_color.svg";

export const StatusBar = ({openlogin}) => {

    const [ isOpen, setIsOpen ] = useState(false);
    const [ autenticado, setAutenticado ] = useState(false);
    const [ nome , setNome ] = useState();
    const [ login , setLogin ] = useState(false);
    const [ openLogin , setOpenLogin ] = useState(openlogin)
    const [ email , setEmail ] = useState('');
    const [ password , setPassword ] = useState('');
    const [ message , setMessage ] = useState('');

    const carousel = useRef();
    const [width, setwidth] = useState(0);

    const auth = useAuth();
    const logout  = useAuth();

    const {user}  = useAuth();
    const navigation = useNavigate();
    
    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setOpenLogin(false);
    };

    const handleLogin = () => {
        setOpenLogin(!openLogin);
        setIsOpen(false);
    };

    const handleLogout = () => {
        logout.logout();
        setIsOpen(false);
        setOpenLogin(false);
        navigation("/");
    };

    async function handleAvancaLogin(e){
        e.preventDefault();
        setMessage('');

        try{

           
            await auth.login(email, password);
           
            setLogin(true);
            setAutenticado(true);
            setOpenLogin(false);
            
            navigation("/destino");
            setMessage("Login efetuado com sucesso!");
            //setLogin(true);

        }catch(error){
            //setLogin(false);
            setAutenticado(false);
            console.log("Email ou senha incorretos. ");

        }

        
    }

    useEffect(() => {

        setwidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth);

        const dados = JSON.parse(localStorage.getItem("@perfil:Atualizar"));

        if (dados != null) {

            setEmail(dados.email);
            setPassword(dados.password);

        }

    }, [])

    return (
        <div className="menu">
                
            <div style={{ display: "flex", alignItems: "center" , paddingLeft: "2rem"}}>
                <img src={LogoColor} alt="logo" style={{ marginRight: "1rem"}}/>
                <div className="titulo-menu">sllips</div>
            </div>
            
            <div style={{ display: "flex", alignItems: "center" , justifyContent: "space-between", gap: "2rem", paddingRight: "2rem"}}>
                <div className="titulo-menu">{ user && user.nome }</div>

                <div className="container-menu" style={{ 
                        display: "flex", 
                        alignItems: "center", 
                        width: "100px", 
                        height: "30px",
                        border: "1px solid rgba(85, 85, 85, 1)", 
                        backgroundColor: "#fff", 
                        borderRadius: "3.7rem",
                        justifyContent: "center" ,
                        paddingTop: "5px"
                    }}>

                    <div onClick={toggleMenu} style={{ cursor: "pointer" }}>
                        {isOpen  ? <FiX size={24} /> : <FiMenu size={24} />}
                    </div>

                    {isOpen  && (
                        <div style={{
                        position: "absolute", 
                        top: "57px", 
                        backgroundColor: "#fff", 
                        borderRadius: "5px", 
                        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                        zIndex: 1,
                        paddingTop: 10
                        }}>
                        <ul style={{ minWidth: "100px", listStyle: "none", padding: "10px", margin: 0}}>
                            
                            { !autenticado ?
                                <div onClick={handleLogin} style={{ display: "flex", alignItems: "center", gap: "6px", flexDirection: "row"}}>
                                    <FiLogIn size={24} />
                                    <a className="titulo-login" style={{ textDecoration: "none"}} href="#login">Login</a>
                                </div>

                                :

                                <div onClick={handleLogout} style={{ display: "flex", alignItems: "center", padding: "10px", gap: "10px", flexDirection: "row"}}>
                                    <FiLogOut size={24} />
                                    <a className="titulo-login" style={{ textDecoration: "none"}} href="/">Sair</a>
                                </div>
                            }

                        </ul>
                        </div>
                    )}

                    { (openLogin || openlogin) &&
                    
                        <div style={{
                            marginTop:"2rem",
                            minWidth: "350px",
                            marginRight: "26rem ",
                            gap: "10px",
                            padding: "10px",
                            position: "absolute", 
                            top: "50px", 
                            backgroundColor: "#fff", 
                            borderRadius: "5px", 
                            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                            zIndex: 1,
                            paddingTop: 10
                            }}>
                            <form onSubmit={handleAvancaLogin}>
                                <section className="home_corpoCEOM">
                                        
                                    <div className="corpoA flex" >

                                        <div className="ContainerAtualizar">
                                            <div className="textlogin">Bem vindo!</div>
                                            <label className="text2"><br />Digite seu email e senha para efetuar login caso já tenha efetuado o cadastro!</label>
                                        </div>

                                        <InputText
                                            type="email"
                                            title="Email"
                                            placeholder="Digite seu Email"
                                            value={email}
                                            onchange={item => setEmail(item.target.value)}
                                            msnWarning="Informe seu email."
                                            required={true}
                                            autocomplete={email}
                                        />

                                        <InputText
                                            type="password"
                                            title="Senha"
                                            placeholder="Digite sua senha"
                                            value={password}
                                            onchange={item => setPassword(item.target.value)}
                                            msnWarning="Informe sua senha."
                                            required={true}
                                            autocomplete={""}
                                        />

                                        {login && <div className="text3">Usuário ou senha incorreto!</div>}
                                        

                                        <button className="btncadastrar" type="submit">Logar</button>
                                        { message && 
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "12px", fontFamily: "Poppins", fontWeight: "bold", paddingTop: "10px", color: "red" }}> 
                                                {message} 
                                            </div>
                                        }
                                        

                                    </div>

                                </section>
                            </form>
                        </div>
                    
                    }
                </div>
                
            </div>

        </div>
        
    )
}
