// import "../../styles/cadastro.css";
import { useNavigate } from "react-router-dom";
import Rodape from "../../components/Rodape";
import BtnTipoCadastro from "../../components/BtnTipoCadastro";

import { useState } from "react";
import { useEffect } from "react";

import LogoColor from "../../assets/image/logo_color.svg";

const EstouPassagem = () => {

    const navigation = useNavigate();

    const [naoHospedado, setNaoHospedado] = useState("");
    const [hospedadoOutroMunicipio, setHospedadoOutroMunicipio] = useState("");


    const passagem = {

        nao_hospedado: naoHospedado,
        hospedado_outro_municipio: hospedadoOutroMunicipio,

    };
    

    const tipoCadastro = (naoHospedado,HospedadoOutroMunicipio,cadastradoOutroMunicipio,tela) =>{
        passagem.nao_hospedado = naoHospedado;
        passagem.hospedado_outro_municipio = HospedadoOutroMunicipio;

        localStorage.setItem("@passagem:Etapa", JSON.stringify({ passagem }));

        navigation(tela)
        
    }


    const handleAvanca = () => {

        navigation("/NovoPassagem");
    }

    useEffect(() => {


        const dados = JSON.parse(localStorage.getItem("@passagem:Etapa"));

        if (dados != null) {

            setNaoHospedado(dados.passagem.naoHospedado);
            setHospedadoOutroMunicipio(dados.passagem.hospedadoOutroMunicipio);

        }

    }, []);


    useEffect(() => {


        const dados = JSON.parse(localStorage.getItem("@passagem:EtapaP"));
        if (dados != null) {

            setNaoHospedado(dados.passagem.naoHospedado);
            setHospedadoOutroMunicipio(dados.passagem.hospedadoOutroMunicipio);

        }
    }, []);


    return (


        <section className="container" onSubmit={handleAvanca}>

            <section className="home_topo_conteudo">
                <img src={LogoColor} alt="logo" className="logoColor" />
                <img src={LogoColor} alt="logo" className="logo" />
            </section>


            <section className="corpoModelo flex">

                <div className="corpo1 flex">

                    <div className="titulo-G">Aproveite e<br />hospede em nosso município!</div>
                    <label className="subtitulo-r"><br />Somos considerados um dos destino mais seguros para se conhecer!
                        Sendo assim é necessário a <strong>Cadastro Turístico</strong>
                        para aproveitar o máximo sua experiência com segurança!</label><br />
                    <div className="subtitulo-m marginBotton">Isso levará alguns segundos!</div>

                </div>


                <div className="corpo2 flex">


                    <BtnTipoCadastro
                        onclick={() => tipoCadastro(true,false,false,"/Novo")}
                        title="Não estou hospedado"

                        value={naoHospedado}
                        onchange={item => setNaoHospedado(item.target.value)}

                        descricao="Caso não esteja hospedado em outro município e esteja 
                    apenas de passagem, esta é a opção certa pra você!"
                    />
                    <BtnTipoCadastro
                        // onclick={() => navigation("/Novo")}
                        onclick={() => tipoCadastro(false,true,false,"/Novo")}
                        title="Estou hospedado em outro município"

                        value={hospedadoOutroMunicipio}
                        onchange={item => setHospedadoOutroMunicipio(item.target.value)}


                        descricao="Caso não tenha feito o cadastro na plataforma sllips no
                    município que você está hospedado. Esta é a opção certa pra você!"
                    />
                   

                </div>




            </section>

            <Rodape
                valorBar={16.6}
                voltar={() => navigation(-1)}
            />

        </section>
    )
}
export default EstouPassagem;